import React from "react";
import useAppSelector from "hooks/useAppSelector";
import CartElementOswTreatmentBooked from "../CartElementOswTreatmentBooked";
import CartElementItineraryOsw from "components/cartElements/CartElementItineraryOsw";
type Props = {
  date: string;
};

const CartElementOswTreatment: React.FC<Props> = ({ date }) => {
  const allCartOswTreatmentsInCart = useAppSelector((state) => state.cart.osw);
  const allBookedOswTreatmentsInCart = useAppSelector(
    (state) => state.bookings.osw
  );

  return (
    <div className="w-full my-2 text-grey-darker">
      {allCartOswTreatmentsInCart[date]?.map((treatment) => (
        <CartElementItineraryOsw
          key={`${treatment.treatmentId}-${treatment.scheduledDay}-${treatment.scheduledTime}`}
          title={treatment.title}
          image={treatment.image}
          itineraryId={treatment.itineraryId}
          price={treatment.price.toString()}
          appointmentId={treatment.appointmentId}
          scheduledDay={treatment.scheduledDay}
          scheduledTime={treatment.scheduledTime}
          duration={treatment.duration}
          fullName={treatment.fullName}
        />
      ))}

      {allBookedOswTreatmentsInCart[date]?.map((treatment) => (
        <CartElementOswTreatmentBooked
          key={`${treatment.treatmentId}-${treatment.scheduledDay}-${treatment.scheduledTime}`}
          tooltipText={`Price paid for this<br/>treatment (total) £${treatment.price}`}
          name={treatment.title}
          image={treatment.image}
          imageCategory="cruiseExcursion"
          linkTo={`/spa-and-wellness/${treatment.itineraryId}`}
          transactionId={treatment.transactionId}
          treatmentId={treatment.treatmentId}
          appointmentId={treatment.appointmentId}
          price={treatment.price}
          time={treatment.scheduledTime}
        >
          <ul className="list-disc my-2 xl:flex-grow">
            <li className="ml-4">Scheduled for {treatment.scheduledDay}</li>
            <li className="ml-4">Start time {treatment.scheduledTime}</li>
            {treatment.duration !== 0 && (
              <li className="ml-4">Duration {treatment.duration} Minutes</li>
            )}
            <li className="ml-4">Pax name: {treatment.fullName}</li>
          </ul>
        </CartElementOswTreatmentBooked>
      ))}
    </div>
  );
};

export default CartElementOswTreatment;
