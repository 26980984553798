import { imagePath } from "../utils/imageUtils";
import useAppSelector from "../hooks/useAppSelector";

const TravelRequirementsMessage = () => {
  const isRiverUrl = useAppSelector((state) => state.appConfig.isRiverUrl);

  const linkUrl = isRiverUrl
    ? "https://www.tui.co.uk/destinations/info/river-cruise-with-confidence"
    : "https://www.tui.co.uk/destinations/info/cruise-with-confidence";

  const linkText = isRiverUrl ? "TUI River Cruises" : "Marella Cruises";

  return (
    <div className="ml-3 flex-grow">
      Head over to the{" "}
      <a
        href={linkUrl}
        className="underline"
        target="_blank"
        rel="noopener noreferrer"
      >
        Cruise with Confidence Hub
      </a>{" "}
      detailing what you need to do to prepare to set sail with {linkText}, from
      testing and entry requirements to re-entry requirements for the UK.
    </div>
  );
};

const TravelRequirementsHeader = () => {
  const showItinerary = useAppSelector(
    (state) => state.location.isItineraryShown
  );
  const showDashboard = useAppSelector(
    (state) => state.location.isDashboardShown
  );

  if (!showDashboard && !showItinerary) {
    return null;
  }

  return (
    <header className="bg-grey-lighter print-hidden">
      <div className="container mx-auto py-4">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <img
              src={imagePath("exclamation-circled.svg")}
              alt=""
              className="w-6"
            />
          </div>
          <TravelRequirementsMessage />
        </div>
      </div>
    </header>
  );
};

export default TravelRequirementsHeader;
