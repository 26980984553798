import { FC, useCallback, useEffect, useState } from "react";
import { useSurveyContext } from "../../../context/SurveyContext";
import useGet from "../../../hooks/useGet";
import { postToHub } from "../../../actions/networkActions";
import {
  getErrors,
  getNrChildQuestionsForPart,
  isFormValid,
  isQuestionShown,
  getSections,
  contactDetailsRiverFormSetup,
  dietaryInformationRiverFormSetup,
  passportInformationRiverFormSetup,
  additionalInformationRiverFormSetup,
} from "../../../utils/questionnaireUtils";
import Progress from "../questions/Progress/Progress";
import Question from ".././questions/Question";
import browserHistory from "../../../utils/history";
import SvgIcon from "../../atoms/SvgIcon";
import BackLink from "../../molecules/BackLink";
import CardCapture from "../../../modules/pxp/CardCapture";
import {
  RouteComponentProps,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import useAppSelector from "hooks/useAppSelector";
import { sanitizeHTML } from "utils/sanitizeHTML/sanitizeHTML";
import usePostRiverGuestInfo from ".././hooks/usePostRiverGuestInfo";
import { useGetRiverGuestInfo } from "../hooks/useGetRiverGuestInfo";
import InfoBox from ".././InfoBox/InfoBox";
import usePatchRiverGuestInfo from "../hooks/usePatchRiverGuestInfo";
import Loader from "components/atoms/Loader";

interface IMatchState {
  passengerId: string;
  token: string;
}

interface ILocationState {
  section: any;
}

type OwnProps = RouteComponentProps & {};

const getPassportInformationForRiver = ({ survey }: any) => {
  if (
    survey?.answers?.documentNumber ||
    survey?.answers?.documentIssuePlace ||
    survey?.answers?.documentExpiryDate ||
    survey?.answers?.fullName
  ) {
    return {
      documentNumber: survey.answers.documentNumber,
      documentIssuePlace: survey.answers.documentIssuePlace,
      documentExpiryDate: survey.answers.documentExpiryDate,
      fullName: survey.answers.fullName,
    };
  }

  return null;
};

const PassengerForm: FC<OwnProps> = () => {
  const location = useLocation<ILocationState>();
  const match = useRouteMatch<IMatchState>();
  const isRiverUrl = useAppSelector((state) => state.appConfig.isRiverUrl);

  const { survey, dispatch } = useSurveyContext();
  const [portalLoaded, setPortalLoaded] = useState(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);

  const passengerId = match.params.passengerId;
  const token = match.params.token;
  const questionsUrl = token
    ? `precruise/guest/questions/travelInfo/${token}`
    : `precruise/questions/travelInfo/${passengerId}`;

  const initialSectionSet = location?.state?.section !== undefined;
  const initialSection = location?.state?.section || 0;
  const [{ activeSection, activePart, previousParts }, setActiveSection] =
    useState<any>({
      activeSection: initialSection,
      activePart: 0,
      previousParts: [],
    });
  const [submitError, setSubmitError] = useState("");

  const { riverGuestInfoData, isFetching } = useGetRiverGuestInfo(
    isRiverUrl ? passengerId : ""
  );

  const { postRiverGuestInfo } = usePostRiverGuestInfo();

  const { patchRiverGuestInfo } = usePatchRiverGuestInfo();

  const data = useGet(questionsUrl, {});

  useEffect(() => {
    if (isRiverUrl) {
      data.paxId = riverGuestInfoData.paxId;
      data.isLead = data?.passenger?.isLead || false;
      data.isTravelInfoCompleted = riverGuestInfoData?.isTravelInfoCompleted;
      data.isTravelInfoLocked = riverGuestInfoData?.isTravelInfoLocked;

      data.contactInformation = contactDetailsRiverFormSetup;
      data.contactInformation.answers.email =
        riverGuestInfoData.contactInformation.email;
      data.contactInformation.answers.confirmEmail =
        riverGuestInfoData.contactInformation.email;
      data.contactInformation.answers.phoneNumber =
        riverGuestInfoData.contactInformation.phoneNumber;

      data.dietaryInformation = dietaryInformationRiverFormSetup;
      data.dietaryInformation.answers.restriction =
        riverGuestInfoData.dietaryInformation.restriction;

      data.passportInformation = passportInformationRiverFormSetup;
      data.passportInformation.answers.birthDate = riverGuestInfoData.birthDate;

      data.passportInformation.answers.fullName =
        riverGuestInfoData?.passportInformation?.fullName;
      data.passportInformation.answers.documentNumber =
        riverGuestInfoData?.passportInformation?.documentNumber;
      data.passportInformation.answers.documentIssuePlace =
        riverGuestInfoData?.passportInformation?.documentIssuePlace;
      data.passportInformation.answers.documentExpiryDate =
        riverGuestInfoData.passportInformation?.documentExpiryDate;

      data.additionalInformation = additionalInformationRiverFormSetup;
      data.additionalInformation.answers.additionalInformation =
        riverGuestInfoData.additionalInformation;
    }
  }, [data, isRiverUrl, riverGuestInfoData]);

  useEffect(() => {
    const { passenger, ...categories }: any = data;

    const questions: { [key: string]: any } = {};
    const answers: { [key: string]: any } = {};
    const parts: { [key: string]: any } = {};

    for (const category in categories) {
      questions[category] = categories[category].questions;
      parts[category] = categories[category].parts;

      if (categories[category].answers) {
        for (const questionId in categories[category].answers) {
          answers[questionId] = categories[category].answers[questionId];
        }
      }
    }

    dispatch({
      type: "SET_SURVEY_DATA",
      passenger,
      answers,
      questions,
      parts,
      card: categories.cardDetails,
    });
  }, [data, riverGuestInfoData, dispatch]);

  const moveBack = useCallback(() => {
    // handle back to review from the first part in case of entering the form via "edit" button on review page
    if (initialSectionSet && activePart === 0) {
      const reviewUrl = token
        ? `/precruise/guest/${passengerId}/review/${token}`
        : `/precruise/passenger/${passengerId}/review`;

      browserHistory.push(reviewUrl);
      return;
    }

    // from the start of the form, go back to previous page from the history
    if (activeSection === 0 && activePart === 0) {
      browserHistory.go(-1);
      return;
    }

    // add the current location to the history in order
    browserHistory.push(location);

    // actual moving back of the form
    if (activePart > 0) {
      setActiveSection({
        activeSection,
        activePart: activePart - 1,
        previousParts: previousParts.slice(0, -1),
      });
    } else {
      setActiveSection({
        activeSection: activeSection - 1,
        activePart: previousParts.slice(-1)[0],
        previousParts: previousParts.slice(0, -1),
      });
    }
  }, [activePart, activeSection, initialSectionSet]);

  // add the current location to the history, to prevent browsers back behaviour to go to previous url
  useEffect(() => {
    browserHistory.push(location);
  }, []);

  // declare listener on history and handle any POP actions (back button)
  useEffect(() => {
    return browserHistory.listen((location, action) => {
      if (location.pathname && action === "POP") {
        moveBack();
      }
    });
  }, [moveBack]);

  if (!survey.passenger || !survey.passenger.passengerId) {
    return <div className="container mx-auto mt-12">Loading...</div>;
  }

  const section = getSections(isRiverUrl)[activeSection];
  const questions =
    survey.questions[section.hubId]?.filter(
      isQuestionShown(survey.passenger)
    ) || [];
  const partInfo = survey.parts[section.hubId]
    ? survey.parts[section.hubId][activePart]
    : {};
  const nrParts = survey.parts && survey.parts[section.hubId]?.length;

  const buttonEnabled = isFormValid(
    questions,
    survey.answers,
    survey.passenger,
    activePart
  );

  const nrQuestionsVisibleForNextPart = () => {
    const nextPart = activePart + 1;

    return (
      questions.filter((question: any) => question.part === nextPart).length +
      questions.reduce(
        (sum: number, question: any) =>
          sum +
          getNrChildQuestionsForPart(
            question,
            survey.answers[question.id],
            nextPart
          ),
        0
      )
    );
  };
  const submit = async () => {
    if (validateForm(questions) && !submitInProgress) {
      window.scrollTo(0, 0);
      // move to next part of the same section if there are still some questions to be answered
      if (nrParts > activePart + 1 && nrQuestionsVisibleForNextPart() > 0) {
        setActiveSection({
          activeSection,
          activePart: activePart + 1,
          previousParts: [...previousParts, activePart],
        });
        return;
      }

      // submit the section data
      const body: any = {
        passengerId,
        category: getSections(isRiverUrl)[activeSection].hubId,
        answers: {},
      };
      for (let questionId of survey.activeFields) {
        const answerVal = survey.answers[questionId];
        body.answers[questionId] = answerVal;
      }

      setSubmitInProgress(true);
      const submitUrl = token
        ? `precruise/guest/submitAnswers/${token}`
        : "precruise/questions/submitAnswers";

      let result;

      if (isRiverUrl) {
        const payload = {
          birthDate: survey.answers.birthDate,
          additionalInformation: survey.answers.additionalInformation,
          contactInformation: {
            phoneNumber: survey.answers.phoneNumber,
            email: survey.answers.email,
          },
          dietaryInformation: {
            restriction: survey.answers.restriction,
          },
        };

        if (riverGuestInfoData.paxId) {
          await patchRiverGuestInfo({
            passengerId,
            payload: {
              ...payload,
              passportInformation: getPassportInformationForRiver({
                survey,
              }),
            },
          });
        } else {
          await postRiverGuestInfo({
            passengerId,
            payload: {
              ...payload,
              passportInformation: getPassportInformationForRiver({
                survey,
              }),
            },
          });
        }

        setSubmitInProgress(false);
      } else {
        result = await postToHub(submitUrl, body);

        setSubmitInProgress(false);

        if (result !== "") {
          setSubmitError(
            "There has been an error with submitting your data, please check your answers and try again"
          );
          return;
        }
      }

      setSubmitError("");

      if (initialSectionSet) {
        const reviewUrl = token
          ? `/precruise/guest/${passengerId}/review/${token}`
          : `/precruise/passenger/${passengerId}/review`;

        browserHistory.push(reviewUrl);
        return;
      }

      if (activeSection + 1 === getSections(isRiverUrl).length) {
        // redirect user to the finish form page
        const finishUrl = isRiverUrl
          ? // for river skip the finish form where pin can be entered
            token
            ? `/precruise/guest/${passengerId}/review/${token}`
            : `/precruise/passengers`
          : // for marella show the finish form where pin can be entered
          token
          ? `/precruise/guest/${passengerId}/finish/${token}`
          : `/precruise/passenger/${passengerId}/finish`;

        browserHistory.push(finishUrl);
      } else {
        setActiveSection({
          activePart: 0,
          previousParts: [...previousParts, activePart],
          activeSection: activeSection + 1,
        });
      }
    }
  };

  const validateForm = (questions: any) => {
    const errors = getErrors(
      questions,
      survey.answers,
      survey.passenger,
      activePart
    );

    dispatch({
      type: "SET_ERRORS",
      errors,
    });

    return Object.keys(errors).length === 0;
  };

  let backTitle = "Back to ";
  if (activePart > 0) {
    backTitle += survey.parts[section.hubId][activePart - 1].title;
  } else {
    if (initialSectionSet) {
      backTitle += "review page";
    } else {
      if (activeSection > 0) {
        const prevSectionParts =
          survey.parts[getSections(isRiverUrl)[activeSection - 1].hubId];
        backTitle += prevSectionParts[prevSectionParts.length - 1].title;
      }
    }
  }

  return (
    <>
      <div className="bg-grey-lightest-2 py-4 border-b">
        <div className="container mx-auto">
          {/*progress bar*/}
          <Progress activeSection={activeSection} />
        </div>
      </div>
      <div className="container mx-auto mt-4">
        {(activeSection > 0 || activePart > 0 || initialSectionSet) && (
          <div className="mr-4">
            <button
              className="text-blue inline-flex items-center hover:underline"
              onClick={moveBack}
            >
              <SvgIcon name="chevron-left" className="w-4 h-4 inline" />{" "}
              {backTitle}
            </button>
          </div>
        )}
        {activeSection === 0 && activePart === 0 && !initialSectionSet && (
          <div className="mr-4">
            <BackLink
              to={"/precruise/passengers"}
              text="Back to Travel information dashboard"
            />
          </div>
        )}

        {isRiverUrl && isFetching ? (
          <div className="w-full">
            <Loader />
          </div>
        ) : (
          <>
            {/* section title */}
            <h1 className="my-6">{partInfo.title}</h1>

            {/* info text for some sections set in HTML */}
            {partInfo.partInfoHtml && (
              <div
                dangerouslySetInnerHTML={sanitizeHTML({
                  html: partInfo.partInfoHtml,
                })}
              />
            )}

            {/* info text for some sections */}
            {partInfo.partInfoText && (
              <div>
                <p className="text-lg text-grey-darker leading-middle mt-3 mb-4">
                  {partInfo.partInfoText}
                </p>
              </div>
            )}

            {/* Info Box section at the start primary */}
            {partInfo.infoBlockStartPrimary && (
              <InfoBox infoText={partInfo.infoBlockStartPrimary} />
            )}

            {/* Info Box section at the start secondary */}
            {partInfo.infoBlockStartSecondary && (
              <InfoBox infoText={partInfo.infoBlockStartSecondary} />
            )}

            {/* Credit card stuff only shown when section is CC */}
            {section.hubId === "cardDetails" && (
              <CardCapture
                passengerId={passengerId}
                card={survey.card}
                backToReview={initialSectionSet}
                token={token}
                submit={submit}
              />
            )}

            {/* questions */}
            {portalLoaded && (
              <div
                className={
                  partInfo.style === "box" ? "bg-grey-lightest p-4 border" : ""
                }
              >
                {questions.map((question: any, index: number) => (
                  <Question
                    key={question.id}
                    activePart={activePart}
                    isFirst={index === 0}
                    question={question}
                  />
                ))}
              </div>
            )}

            <LastQuestionsPortal setPortalLoaded={setPortalLoaded} />

            {submitError && (
              <div className="mt-4 text-red-tui">{submitError}</div>
            )}

            {section.hubId !== "cardDetails" && (
              <div className="mt-6 mb-8 xl:mb-12">
                <button
                  className={`action-button w-mobile ${
                    buttonEnabled && !submitInProgress
                      ? "action-button-enabled"
                      : "action-button-disabled"
                  }`}
                  onClick={submit}
                >
                  Next
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

type LastQuestionsProps = {
  setPortalLoaded: (value: boolean) => void;
};

const LastQuestionsPortal: FC<LastQuestionsProps> = ({ setPortalLoaded }) => {
  useEffect(() => {
    setPortalLoaded(true);
  }, []);

  return <div id="last-questions-portal"></div>;
};

export default PassengerForm;
