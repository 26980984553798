import { FC } from "react";
import { useSurveyContext } from "../../../context/SurveyContext";
import TextInputWithLabel from "../../TextInputWithLabel";

type Props = {
  question: any;
  activePart: any;
  validate: (question: any, answer: any, secondaryAnswer?: string) => void;
};

const EmailConfirmQuestion: FC<Props> = ({
  question,
  activePart,
  validate,
}) => {
  const {
    survey: { answers },
    setAnswer,
  } = useSurveyContext();

  const answer = answers[question.id] || "";
  const answerSecondary =
    question.id === "email" ? answers.confirmEmail : answers.email;

  const isShown = question.part === activePart;

  const handleOnChangeEmail = (value: string) => {
    validate(question, value, answerSecondary);
    setAnswer(question.id, value);
  };

  const handleOnBlurEmailInput = () => {
    validate(question, answer, answerSecondary);
  };

  return (
    <div className={isShown ? "" : "hidden"}>
      <TextInputWithLabel
        label={question.questionText}
        subLabel={question.questionInfo}
        subLabelClasses="text-grey-darker"
        value={answer}
        _onChange={handleOnChangeEmail}
        onBlur={handleOnBlurEmailInput}
        placeholder=""
        labelClassNames="input-label"
      />
    </div>
  );
};

export default EmailConfirmQuestion;
