import { IPassenger } from "interfaces/Common/IPassenger";
import { isEmail } from "utils/validationUtils";
import { addYears, subYears } from "date-fns";

export const isQuestionShown = (passenger: IPassenger) => (question: any) => {
  if (question.disabled) {
    return false;
  }

  if (question.filters) {
    // loop over filters and check each one, returning false if one fails
    return question.filters.every((filter: any) =>
      passengerMatchesFilter(filter, passenger)
    );
  }

  return true;
};

export const getNrChildQuestionsForPart = (
  question: any,
  answer: any,
  part: any
) => {
  return (
    question?.questions?.[answer]?.filter(
      (childQuestion: any) => childQuestion?.part === part
    )?.length ?? 0
  );
};

export const passengerMatchesFilter = (filter: any, passenger: any) => {
  switch (filter.operator) {
    case "eq":
      return passenger[filter.key] === filter.value;

    // TODO - add type validation if needed
    case "lt":
      return passenger[filter.key] < filter.value;

    case "gt":
      return passenger[filter.key] > filter.value;

    default:
      return false;
  }
};

export const getAnswerErrorMessage = (
  question: { id: any; validation: string[] },
  answer: string,
  secondaryAnswer?: string
) => {
  let errorMessage = "";
  const rules = question.validation || [];

  rules.every((rule: string) => {
    // rule should looks like: one of these min|5, max|5, required
    const [operation, ...params] = rule.split("|");

    switch (operation) {
      case "required":
        if (!answer || answer.length === 0) {
          errorMessage = "This field is required, please fill it out";
          return false;
        }
        return true;

      case "min":
        if (answer.length < Number(params[0])) {
          errorMessage = "This field has a minimum length of " + params[0];
          return false;
        }
        return true;

      case "max":
        if (answer.length > Number(params[0])) {
          errorMessage = "This field has a maximum length of " + params[0];
          return false;
        }
        return true;

      case "phone":
        if (!answer) {
          return true;
        }

        if (!/^\+?[0-9- ]{6,15}$/.test(answer)) {
          errorMessage = "This field must be a valid phone number.";
          return false;
        }
        return true;

      case "email":
        if (answer.length === 0) {
          errorMessage = "";
          return true;
        }

        const isAnswerIsEmail = isEmail(answer);
        if (!isAnswerIsEmail) {
          errorMessage = "Please enter a valid email address";
          return false;
        }

        if (secondaryAnswer && answer !== secondaryAnswer) {
          errorMessage = "Please review both email fields - they should match";
          return false;
        }

        return true;

      case "date":
        // answer should be string date in format "08/09/1995"
        const dateRegexp =
          /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(\d{4})$/;
        const isAnswerIsDate = dateRegexp.test(answer);

        if (!isAnswerIsDate) {
          errorMessage = "Please enter a valid date";
        }

        return isAnswerIsDate;

      default:
        return true;
    }
  });

  return errorMessage;
};

export const getQuestionErrors = (
  question: any,
  activePart: any,
  answers: any,
  passenger: IPassenger
) => {
  const errors = {};

  const answer = answers[question.id];
  // validate answer to question itself, if it is currently shown
  const errorMessage =
    question.part === activePart
      ? getAnswerErrorMessage(question, answer)
      : false;
  if (errorMessage) {
    //@ts-ignore any
    errors[question.id] = errorMessage;
  }

  // loop over nested visible fields and validate each one, building up the object of errors as we go
  let nestedErrors = {};
  const nestedQuestions =
    question.questions &&
    question.questions[answer]?.filter(isQuestionShown(passenger));
  if (nestedQuestions) {
    nestedQuestions.forEach((nestedQuestion: any) => {
      nestedErrors = {
        ...nestedErrors,
        ...getQuestionErrors(nestedQuestion, activePart, answers, passenger),
      };
    });
  }

  return { ...errors, ...nestedErrors };
};

export const getErrors = (
  questions: any,
  answers: any,
  passenger: IPassenger,
  activePart: any
) => {
  let errors = {};
  questions.forEach((question: any) => {
    errors = {
      ...errors,
      ...getQuestionErrors(question, activePart, answers, passenger),
    };
  });

  return errors;
};

export const isFormValid = (
  questions: any,
  answers: any,
  passenger: IPassenger,
  activePart: any
) => {
  if (questions.length === 0) {
    return false;
  }

  return questions.every(
    (question: any) =>
      Object.keys(getQuestionErrors(question, activePart, answers, passenger))
        .length === 0
  );
};

export const contactDetailsRiverFormSetup = {
  questions: [
    {
      id: "email",
      part: 0,
      questionText: "Email address",
      type: "email",
      validation: ["required", "email"],
      addToReport: "true",
    },
    {
      id: "confirmEmail",
      part: 0,
      questionText: "Confirm email address",
      type: "email",
      addToReport: "true",
      validation: ["required", "email"],
    },
    {
      id: "phoneNumber",
      part: 0,
      questionText: "Mobile Number (including code)",
      type: "text",
      addToReport: "true",
      validation: ["required", "phone"],
    },
  ],
  parts: [
    {
      title: "Contact Details",
      partInfoText:
        "Please add your contact details below. We will only contact you to provide you with important updates relating to your cruise.",
      style: "box",
    },
  ],
  answers: {
    email: "",
    confirmEmail: "",
    phoneNumber: "",
  },
};

export const dietaryInformationRiverFormSetup = {
  questions: [
    {
      id: "restriction",
      part: 0,
      questionText:
        "Please provide any details regarding any allergies or medical dietary requirements.",
      type: "textarea",
      validation: ["max|250"],
      addToReport: "true",
    },
  ],
  parts: [
    {
      title: "Allergies & Dietary Requirements",
      partInfoText:
        "Would you like to provide any details regarding any allergies or medical dietary requirements?",
      infoBlockStartPrimary:
        "Any information you provide will only be seen by the TUI River Cruises team and our service providers.",
      infoBlockStartSecondary:
        "We will do our best to accommodate dietary requirements, our food is prepared in kitchens that may contain allergens. Please discuss any specific dietary requirements with the restaurant manager at the start of you cruise.",
      style: "box",
    },
  ],
  answers: {
    restriction: "",
  },
};

export const passportInformationRiverFormSetup = {
  questions: [
    {
      id: "fullName",
      part: 0,
      questionText: "Name",
      type: "text",
      addToReport: "true",
      validation: ["required", "max|255"],
    },
    {
      id: "birthDate",
      part: 0,
      questionText: "Date of Birth",
      type: "datepicker",
      initialMinDate: subYears(new Date(), 120),
      initialMaxDate: new Date(),
      validation: ["required", "date"],
      addToReport: "true",
    },
    {
      id: "documentNumber",
      part: 0,
      questionText: "Document Number",
      type: "text",
      addToReport: "true",
      validation: ["required", "max|255"],
    },
    {
      id: "documentIssuePlace",
      part: 0,
      questionText: "Document Issue Place",
      type: "text",
      addToReport: "true",
      validation: ["required", "max|255"],
    },
    {
      id: "documentExpiryDate",
      part: 0,
      questionText: "Document Expiry Date",
      type: "datepicker",
      initialMinDate: new Date(),
      initialMaxDate: addYears(new Date(), 10),
      addToReport: "true",
      validation: ["required", "date"],
    },
  ],
  parts: [
    {
      title: "Passport Information",
      partInfoText:
        "In order to assist with your check in onboard the ship and make this as smooth as possible, please provide the passport information for you and your party:",
      style: "box",
    },
  ],
  answers: {
    birthDate: "",
    documentNumber: "",
    documentIssuePlace: "",
    documentExpiryDate: "",
    fullName: "",
  },
};

export const additionalInformationRiverFormSetup = {
  questions: [
    {
      id: "additionalInformation",
      part: 0,
      questionText:
        "Please take the time to note down any requests you may have during your cruise such as cabin requests, special occasions/celebrations etc or any other important information you’d like us to know.",
      type: "textarea",
      addToReport: "true",
      validation: ["max|255"],
    },
  ],
  parts: [
    {
      title: "Additional Information",
      partInfoText:
        "Is there anything else that you would like to make us aware of?",
      style: "box",
    },
  ],
  answers: {
    additionalInformation: "",
  },
};

const riverSections = [
  {
    hubId: "contactInformation",
    label: "Contact Details",
    reviewLabel: "Contact Details",
  },
  {
    hubId: "dietaryInformation",
    label: "Allergies & Dietary Requirements",
    reviewLabel: "Allergies & Dietary Requirements",
  },
  {
    hubId: "passportInformation",
    label: "Passport Information",
    reviewLabel: "Passport Information",
  },
  {
    hubId: "additionalInformation",
    label: "Additional Information",
    reviewLabel: "Additional Information",
  },
];

const marellaSections = [
  {
    hubId: "emergencyContact",
    label: "Emergency Contact",
    reviewLabel: "Emergency Contact",
  },
  {
    hubId: "medical",
    label: "Medical Information",
    reviewLabel: "Medical Information",
  },
  {
    hubId: "accessibility",
    label: "Accessibility",
    reviewLabel: "Accessibility",
  },
  {
    hubId: "allergy",
    label: "Allergies and diet",
    reviewLabel: "Allergies and diet",
  },
];

export const getSections = (isRiverUrl: boolean) => {
  return isRiverUrl ? riverSections : marellaSections;
};
