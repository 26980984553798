import { FC, useState } from "react";
import browserHistory from "utils/history";
import ContentHeaderImage from "components/ContentHeaderImage";
import PlannerContainer from "components/PlannerContainer";
import "react-image-gallery/styles/css/image-gallery.css";
import SvgIcon from "components/atoms/SvgIcon";
import Price from "components/Price/Price";
import SimpleAvailabilityOption from "components/simpleAvailabilityOption";
import Modal from "components/modals/Modal/Modal";
import { setScrollToItineraryEntry } from "actions/itineraryActions";
import useGet from "hooks/useGet";
import OneSpaWorldAddToPlannerForm from "../OneSpaWorldAddToPlannerForm";
import Loader from "components/atoms/Loader";
import NotificationModal from "components/modals/NotificationModal";
import ModalFooter from "components/modals/ModalFooter";
import { imagePath } from "utils/imageUtils";
import { useGetTimeSlotPrice } from "../hooks/useGetTimeSlotPrice";
import { useGetSpaTreatmentDetails } from "../hooks/useGetSpaTreatmentDetails";
import { buildTestId } from "utils/buildTestId";
import IconWithText from "components/atoms/IconWithText";
import { RouteComponentProps } from "react-router-dom";

type SelectedTreatmentType = {
  id: number;
  price: string;
  openSlotStartDateTime: string;
} | null;

export interface Props
  extends RouteComponentProps<{
    spaTreatmentDate: string;
    spaTreatmentId: string;
    itineraryId: string;
  }> {}

const OneSpaWorldDetails: FC<Props> = ({ match }) => {
  const { passengers } = useGet("precruise/status", []);

  const {
    getSpaTreatmentDetails,
    data: spaTreatment,
    loading: spaTreatmentDetailsLoading,
  } = useGetSpaTreatmentDetails({
    date: match.params.spaTreatmentDate,
    treatmentId: match.params.spaTreatmentId,
  });

  const {
    getTimeSlotPrice,
    data: timeSlotPrice,
    loading: timeSlotPriceLoading,
  } = useGetTimeSlotPrice();

  const [selectedSpaTreatmentTimeSlot, setSelctedSpaTreatment] =
    useState<SelectedTreatmentType>(null);

  const [showPassengerModal, setShowPassengerModal] = useState(false);
  const [notificationModalData, setNotificationModalData] = useState<
    | {
        shouldShow: boolean;
        img: string;
        message: string;
      }
    | undefined
  >(undefined);

  const handleSlotSelect = ({
    id,
    price,
    openSlotStartDateTime,
  }: {
    id: number;
    price: string;
    openSlotStartDateTime: string;
  }) => {
    setSelctedSpaTreatment({ id, price, openSlotStartDateTime });
  };

  const handleClickContinue = () => {
    if (selectedSpaTreatmentTimeSlot) {
      getTimeSlotPrice({
        date: match.params.spaTreatmentDate,
        treatmentId: match.params.spaTreatmentId,
        treatmentSlotId: selectedSpaTreatmentTimeSlot.id,
      });
    }
    togglePassengerModal();
  };

  const togglePassengerModal = () => {
    setShowPassengerModal(!showPassengerModal);
  };

  const handleShowNotificationModal = ({
    img,
    message,
  }: {
    img: string;
    message: string;
  }) => {
    setShowPassengerModal(false);
    setNotificationModalData({ shouldShow: true, img, message });
  };

  const handleCloseNotificationModal = async () => {
    setScrollToItineraryEntry(null);
    setNotificationModalData(undefined);
    setSelctedSpaTreatment(null);
    await getSpaTreatmentDetails();
  };

  if (spaTreatmentDetailsLoading) {
    return <Loader />;
  }

  return (
    <div>
      <PlannerContainer />

      <div className="container mt-4 mb-8 mx-auto">
        <button
          data-testid={buildTestId("container", "spa treatment details")}
          onClick={browserHistory.goBack}
          className="text-blue cursor-pointer print-hidden flex items-center hover:underline"
        >
          <SvgIcon name="chevron-left" className="w-4 h-4 inline" /> Back
        </button>

        <div className="print-text-center">
          <h1 className="uppercase">{spaTreatment?.name}</h1>
        </div>

        <div className="print-text-center my-4 print-my-0">
          <ContentHeaderImage
            altText={spaTreatment?.name + " - header image"}
            resourceId={spaTreatment?.image}
            category="cruiseSpaTreatment"
          ></ContentHeaderImage>
        </div>
        <div className="flex flex-col gap-4 text-blue-dark mb-6">
          <h2>{spaTreatment?.shortDescription}</h2>
          <h3>{spaTreatment?.longDescription}</h3>
          {spaTreatment?.duration !== 0 && (
            <h3>Duration: {spaTreatment?.duration} Minutes</h3>
          )}
        </div>
        <div>
          <div className="mt-4 md:flex md:flex-wrap md:-mx-2">
            {spaTreatment?.treatmentOpenSlots?.map(
              ({
                id,
                openSlotPrice,
                openSlotStartTime,
                openSlotStartDateTime,
              }) => {
                return (
                  <SimpleAvailabilityOption
                    key={id}
                    price={openSlotPrice}
                    timeSlot={openSlotStartTime}
                    selected={selectedSpaTreatmentTimeSlot?.id === id}
                    onClick={() =>
                      handleSlotSelect({
                        id,
                        price: openSlotPrice,
                        openSlotStartDateTime: openSlotStartDateTime,
                      })
                    }
                  />
                );
              }
            )}
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-2">
          {/* <IconWithText
            icon={
              <i className="flex items-center justify-center font-bold bg-green rounded-full w-5 h-5">
                £
              </i>
            }
            text="Best Price"
          /> */}
          <IconWithText
            icon={
              <i className="flex items-center text-white justify-center font-bold bg-blue-dark rounded-full w-5 h-5">
                i
              </i>
            }
            text="All prices shown are per person"
          />
        </div>
        <div className="flex items-center justify-between mt-4">
          <h2>Total Cost: </h2>
          <Price
            price={selectedSpaTreatmentTimeSlot?.price.toString() ?? "0"}
          />
        </div>
        <div className="flex justify-end mt-4">
          <button
            className={`action-button 
              ${
                selectedSpaTreatmentTimeSlot
                  ? "action-button-enabled"
                  : "action-button-disabled"
              }`}
            disabled={!selectedSpaTreatmentTimeSlot}
            onClick={handleClickContinue}
          >
            Continue
          </button>
        </div>
      </div>
      <Modal
        show={showPassengerModal && !timeSlotPriceLoading}
        onClose={togglePassengerModal}
        title={spaTreatment?.name ?? "Spa And Wellness"}
        loading={timeSlotPriceLoading}
      >
        <OneSpaWorldAddToPlannerForm
          price={timeSlotPrice ?? 0}
          treatmentId={spaTreatment?.id}
          itineraryId={match?.params?.itineraryId}
          openTimeSlotId={selectedSpaTreatmentTimeSlot?.id}
          openSlotStartDateTime={
            selectedSpaTreatmentTimeSlot?.openSlotStartDateTime
          }
          passengers={passengers}
          onCancel={togglePassengerModal}
          onSuccess={handleShowNotificationModal}
          onError={handleShowNotificationModal}
        />
      </Modal>

      {notificationModalData && (
        <NotificationModal
          show={notificationModalData.shouldShow}
          onClose={handleCloseNotificationModal}
          title={spaTreatment?.name ?? "Spa And Wellness"}
          message={notificationModalData.message}
          img={
            <img
              className="align-middle w-12 h-12 inline-block"
              alt="notification icon"
              src={imagePath(notificationModalData.img)}
            />
          }
          footer={
            <ModalFooter
              actionDisabled={false}
              actionButtonText="Go to planner"
              onActionClick={() => {
                browserHistory.push("/itinerary");
              }}
            />
          }
        />
      )}
    </div>
  );
};

export default OneSpaWorldDetails;
