import useAppSelector from "hooks/useAppSelector";

export const useCheckFeatureFlags = () => {
  const isMxpEnabled = useAppSelector(
    (state) => state.appConfig.featureFlags
  )?.find((flag) => flag.code === "IsMxpEnabled")?.isActive;

  return {
    isMxpEnabled,
  };
};
