import { FC, InputHTMLAttributes, KeyboardEvent, useState } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { parse, format } from "date-fns";
import { enGB } from "date-fns/locale";

// default base styles
import "react-datepicker/dist/react-datepicker.css";

// custom overrides of the styles
import "../resources/css/datepicker.css";

type Props = {
  id: string;
  className: string;
  onChange: (value: string) => void;
  initialValue?: string;
  /**
   * initialMinDate - JavaScript Date Object in format like Wed Oct 08 2025 14:48:29 GMT+0300 (Eastern European Summer Time)
   */
  initialMinDate?: Date;
  /**
   * initialMaxDate - JavaScript Date Object in format like Wed Oct 08 2025 14:48:29 GMT+0300 (Eastern European Summer Time)
   */
  initialMaxDate?: Date;
};

const CalendarPicker: FC<Props> = ({
  onChange,
  id,
  className,
  initialValue = "",
  initialMinDate = new Date(),
  initialMaxDate = new Date(),
}) => {
  const innerInitialValue =
    initialValue === "" ? null : parse(initialValue, "dd/MM/yyyy", new Date());

  const [date, setDate] = useState<Date | null>(innerInitialValue);

  const handleChange = (date: Date) => {
    const formattedDate = format(date, "dd/MM/yyyy");
    onChange(formattedDate);
    setDate(date);
  };

  const onKeyDown = (e: KeyboardEvent) => {
    e.preventDefault();
  };

  return (
    <DatePicker
      dateFormat="dd/MM/yyyy"
      selected={date}
      onChange={handleChange}
      className={className}
      locale={enGB}
      id={id}
      minDate={initialMinDate}
      maxDate={initialMaxDate}
      placeholderText="Select a date"
      showMonthYearDropdown={true}
      popperPlacement="bottom-start"
      popperProps={{
        flip: {
          enabled: false,
        },
        preventOverflow: {
          enabled: true,
          escapeWithReference: false,
        },
      }}
      onKeyDown={onKeyDown}
      autoComplete="off"
      customInput={<DateOnlyInput />}
    />
  );
};

type DateOnlyInputProps = Partial<ReactDatePickerProps> &
  InputHTMLAttributes<HTMLInputElement> & {};

const DateOnlyInput: FC<DateOnlyInputProps> = ({ ...rest }) => {
  const [readonly, setReadonly] = useState<boolean>(false);

  const _onFocus = () => {
    setReadonly(true);
  };

  const _onBlur = () => {
    setReadonly(false);
  };

  const props = { ...rest };
  delete props.onBlur;
  delete props.onFocus;
  delete props.readOnly;

  return (
    <input
      onBlur={_onBlur}
      onFocus={_onFocus}
      type="text"
      readOnly={readonly}
      {...props}
    />
  );
};

export default CalendarPicker;
