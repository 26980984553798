import { FC, useEffect, useState } from "react";
import browserHistory from "../../utils/history";
import ContentHeaderImage from "../../components/ContentHeaderImage";
import RecommendationSlider from "../../components/RecommendationSlider";
import PriceInfoBox from "../../components/PriceInfoBox/PriceInfoBox";
import ActivityDuration from "../../components/molecules/ActivityDuration";
import ExcursionAttributes from "../../components/ExcursionAttributes";
import ContentDividerWithIcon from "../../components/ContentDividerWithIcon";
import PlannerContainer from "../../components/PlannerContainer";
import Modal from "../../components/modals/Modal/Modal";
import ModalGallery from "../../components/modals/ModalGallery";
import AddToPlannerForm from "../../components/AddToPlannerForm";
import { getFromHub } from "../../actions/networkActions";
import { formatPortDate, formatTimeHM } from "../../utils/dateTimeUtils";
import ImportantInformationEntry from "../../components/molecules/ImportantInformationEntry";
import BookedPassengersList from "../../components/BookedPassengersList";
import { imagePath } from "../../utils/imageUtils";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import If from "../../components/molecules/IF";
import SoldOutBox from "../../components/molecules/SoldOutBox";
import GalleryToggleButton from "../../components/GalleryToggleButton";
import SuccessModal from "../../components/modals/SuccessModal";
import { setScrollToItineraryEntry } from "../../actions/itineraryActions";
import { getHubBaseUrl } from "../../config/configUtils";
import SvgIcon from "../../components/atoms/SvgIcon";
import useAppSelector from "../../hooks/useAppSelector";
import { RouteComponentProps } from "react-router-dom";

const ExcursionDetail: FC<
  RouteComponentProps<{
    modal: string;
    excursionId: string;
    itineraryId: string;
  }>
> = ({ match }) => {
  const cart = useAppSelector((state) => state.cart.excursions);

  const [excursion, setExcursion] = useState<any>(null);
  const [itinerary, setItinerary] = useState<any>(null);
  const [currentAvailability, setCurrentAvailability] = useState(0);
  const [images, setImages] = useState<any>(null);
  const [description, setDescription] = useState("");
  const [notes, setNotes] = useState("");
  const [exp360BundleId, setExp360BundleId] = useState("");
  const [excursionLoaded, setExcursionLoaded] = useState(false);
  const [descriptionLoaded, setDescriptionLoaded] = useState(false);
  const [showPassengerModal, setShowPassengerModal] = useState(
    match.params.modal === "passengers"
  );
  const [showImagesModal, setShowImagesModal] = useState(false);
  const [showExp360Modal, setShowExp360Modal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    loadData();
  }, [match.params.itineraryId, match.params.excursionId]);

  const loadData = () => {
    const excursionUrl = `prebooking/cruises/itinerary/${match.params.itineraryId}/excursions/${match.params.excursionId}`;
    getFromHub(excursionUrl, updateExcursion);
    getFromHub(`blobs/images/${match.params.excursionId}`, updateImages);
    getFromHub(`exp360/${match.params.excursionId}`, updateExp360BundleId);
  };

  const updateExcursion = (response: any) => {
    setExcursion(response.excursion);
    setItinerary({ ...response.itinerary, dayOfCruise: response.dayOfCruise });
    setCurrentAvailability(response.currentAvailability);
    setExcursionLoaded(true);

    getFromHub(`descriptions/${response.excursion.id}`, updateDescription);
  };

  const updateDescription = (response: any) => {
    const description = response.find(
      (desc: any) => desc.descriptionType === "Description"
    );
    const descriptionValue = description
      ? description.value.defaultPlainValue
      : "Description missing";

    const notes = response.find(
      (desc: any) => desc.descriptionType === "Notes"
    );
    const notesValue = notes ? notes.value.defaultPlainValue : "";

    setDescription(descriptionValue);
    setNotes(notesValue);
    setDescriptionLoaded(true);
  };

  const updateImages = (imagesResponse: any) => {
    const images = imagesResponse.map((image: any) => {
      return {
        thumbnail: `${getHubBaseUrl()}${image.url}&width=200`,
        original: `${getHubBaseUrl()}${image.url}`,
      };
    });

    setImages(images);
  };

  const updateExp360BundleId = (bundleResponse: any) => {
    let exp360BundleId = "";
    if (bundleResponse.length) {
      exp360BundleId = bundleResponse[0].snippet;
    }

    setExp360BundleId(exp360BundleId);
  };

  const togglePassengerModal = () => {
    setShowPassengerModal(!showPassengerModal);
  };

  const toggleImagesModal = () => {
    setShowImagesModal(!showImagesModal);
  };

  const toggleExp360Modal = () => {
    setShowExp360Modal(showExp360Modal);
  };

  const handleShowSuccessModal = (successMessage: string) => {
    setScrollToItineraryEntry(match.params.itineraryId);
    setShowPassengerModal(false);
    setShowSuccessModal(true);
    setSuccessMessage(successMessage);
  };

  const handleCloseSuccessModal = () => {
    setScrollToItineraryEntry(null);
    setShowSuccessModal(false);
  };

  const formatAgeRestrictions = () => {
    let restrictions = [];

    const minAge = excursion.minimumAge;
    if (minAge > 0) {
      const yearPluralized = minAge === 1 ? "year" : "years";
      restrictions.push(
        `The minimum age for this excursion is ${minAge} ${yearPluralized} old.`
      );
    }

    const maxAge = excursion.maximumAge;
    if (maxAge) {
      restrictions.push(
        `The maximum age for this excursion is ${maxAge} years old.`
      );
    }

    return restrictions.join(" ");
  };

  if (!excursionLoaded || !descriptionLoaded) {
    return <div>Loading...</div>;
  }

  const buttonText =
    cart &&
    cart[match.params.itineraryId] &&
    cart[match.params.itineraryId][match.params.excursionId]
      ? "added to planner"
      : "add to planner";

  const excursionAttributes = excursion.categories.map(
    (category: any) => category.name
  );
  const excursionFacts = [
    `Day ${itinerary.dayOfCruise} of your itinerary (${formatPortDate(
      itinerary.date
    )})`,
    `From ${itinerary.port.name.defaultValue?.toUpperCase()} where the ship is in port: ${formatTimeHM(
      itinerary.arrival,
      "Previous day"
    )} - ${formatTimeHM(itinerary.departure, "Next day")}`,
  ];

  return (
    <div>
      <PlannerContainer />

      <div className="container mt-4 mb-8 mx-auto">
        <button
          onClick={browserHistory.goBack}
          className="text-blue cursor-pointer print-hidden flex items-center hover:underline"
        >
          <SvgIcon name="chevron-left" className="w-4 h-4 inline" /> Back
        </button>

        <div className="print-text-center">
          <h1 className="uppercase">{excursion.name.defaultValue}</h1>
        </div>

        <div className="print-text-center my-4 print-my-0">
          <ContentHeaderImage
            altText={excursion.name.defaultValue + " - header image"}
            resourceId={excursion.id}
            category="cruiseExcursion"
          >
            <GalleryToggleButton
              toggleGallery={
                images && images.length > 1 ? toggleImagesModal : undefined
              }
              toggleExp360Gallery={
                !!exp360BundleId ? toggleExp360Modal : undefined
              }
            />
          </ContentHeaderImage>
        </div>

        <div className="print-hidden">
          <ModalGallery show={showImagesModal} onClose={toggleImagesModal}>
            <ImageGallery
              items={images}
              lazyLoad={true}
              showIndex={true}
              showPlayButton={false}
            />
          </ModalGallery>
        </div>

        <div className="sm:flex sm:flex-row-reverse my-8 print-flex print-flex-row-reverse">
          {/*right container - pricebox and key facts*/}
          <div className="sm:w-1/2 md:w-1/3 xl:w-1/4 print-w-1-4">
            {/* show sold out message or price box depending if excursion is available or not */}
            <If test={currentAvailability > 0}>
              <PriceInfoBox
                classes="w-full text-blue-dark border border-blue-light p-4"
                adultPrice={excursion.price.adult}
                childPrice={excursion.price.child}
                minimumAge={excursion.minimumAge}
                buttonText={buttonText}
                currentAvailability={currentAvailability}
                _onClick={togglePassengerModal}
              />
            </If>

            <If test={currentAvailability <= 0}>
              <SoldOutBox />
            </If>

            <div className="mt-6">
              <div className="uppercase p-4 bg-blue-light text-blue-dark print-py-0">
                Key facts
              </div>
              <div className="bg-blue-lightest p-4 print-py-0">
                <div className="pb-2 border-b print-border-0">
                  <ExcursionAttributes attributes={excursionAttributes} />
                </div>
                <div className="py-2 border-b print-border-0">
                  <ActivityDuration duration={excursion.duration} />
                </div>
                <div className="pt-2">
                  <ExcursionAttributes attributes={excursionFacts} />
                </div>
              </div>
            </div>
          </div>

          {/*left container - about the excursion & excursion itinerary*/}
          <div className="mt-8 sm:mt-0 sm:w-1/2 md:w-2/3 xl:w-3/4 sm:pr-4 print-w-3-4">
            <div className="text-blue-dark mb-6">
              <h2>About the excursion</h2>
              <div className="text-grey-darker my-2">{description}</div>
            </div>

            <ContentDividerWithIcon
              iconAltText="info icon"
              iconSrc={imagePath("infoicon.svg")}
              title="Important information"
              hidden={!(notes || formatAgeRestrictions())}
            />

            <div className="text-grey-darker my-4 px-2 sm:px-4">
              <ImportantInformationEntry
                title="Restrictions for this excursion"
                content={formatAgeRestrictions()}
                isContentInHtml={false}
              />
              <ImportantInformationEntry
                title="Just so you know"
                content={notes}
                isContentInHtml={false}
              />
            </div>
          </div>
        </div>
      </div>

      <RecommendationSlider
        title={
          "Popular excursions from " +
          excursion.port.name.defaultValue?.toUpperCase()
        }
        path={`prebooking/cruises/itinerary/${match.params.itineraryId}/recommendedExcursions`}
      />

      <Modal
        show={showPassengerModal}
        onClose={togglePassengerModal}
        title={excursion.name.defaultValue?.toUpperCase()}
      >
        <BookedPassengersList
          itineraryId={match.params.itineraryId}
          excursionId={match.params.excursionId}
        />
        <AddToPlannerForm
          itineraryId={match.params.itineraryId}
          excursionId={match.params.excursionId}
          adultPrice={excursion.price.adult}
          childPrice={excursion.price.child}
          onCancel={togglePassengerModal}
          onSuccess={handleShowSuccessModal}
        />
      </Modal>

      <SuccessModal
        show={showSuccessModal}
        onClose={handleCloseSuccessModal}
        title="Excursion reservation"
        message={successMessage}
      />
    </div>
  );
};

export default ExcursionDetail;
