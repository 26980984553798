import browserHistory from "utils/history";
import If from "./molecules/IF";
import Price from "./Price/Price";
import ActionButton from "./Buttons/ActionButton";
import useAppSelector from "../hooks/useAppSelector";
import { FC } from "react";
import CartExpirationTimeWarningMessage from "./CartExpirationTimeWarningMessage/CartExpirationTimeWarningMessage";
import CountdownTimer from "./molecules/CountdownTimer/CountdownTimer";

type Props = {
  sticky?: boolean;
};

const PlannerContainer: FC<Props> = ({ sticky }) => {
  const authentication = useAppSelector((state) => state.authentication);
  const cart = useAppSelector((state) => state.cart);
  const cruises = useAppSelector((state) => state.cruises.cruiseDetails);

  return (
    <If test={authentication.isAuthenticated && cruises && cruises.length > 0}>
      <div
        className={`bg-grey-lightest shadow print-hidden py-2 ${
          sticky ? "sticky top-0 z-10" : ""
        }`}
      >
        <div className="container mx-auto flex flex-col md:flex-row md:justify-end">
          <div className="flex grow mb-2 md:mr-4 md:mb-0 h-fit">
            <CartExpirationTimeWarningMessage />
          </div>

          <div className="sm:min-w-[320px] shadow text-blue-dark p-2 sm:p-4 bg-white flex justify-between md:justify-end">
            <div className="flex flex-col">
              <div>
                <Price
                  largePreText={true}
                  preText="Total price"
                  price={cart.totalPrice.toString()}
                />
              </div>
              <div className="mt-1">
                <span className="font-bold">{cart.nrExcursions}</span>
                <span>
                  {cart.nrExcursions === 1 ? " item " : " items "}
                  in your planner
                </span>
              </div>
              <div className="mt-3">
                <CountdownTimer timeLeftValue={cart.cartExpirationTime} />
              </div>
            </div>
            <div className="h-fit sm:ml-4">
              <ActionButton
                onClick={() => {
                  browserHistory.push("/checkout");
                }}
                disabled={cart.nrExcursions === 0}
                classes="px-6"
                buttonText="Continue"
              />
            </div>
          </div>
        </div>
      </div>
    </If>
  );
};

export default PlannerContainer;
