import { IPersonType } from "interfaces/Common/IPassenger";
import {
  calculateYearsDifference,
  checkIfAgeInRange,
  formatDateSlashesToDashes,
} from "utils/dateTimeUtils";

export const getPassengerType = (
  passengerDob: string | undefined,
  itineraryDate: string,
  childAgeRange: string,
  babyAgeRange: string
): IPersonType => {
  // if no dob than set passenger type to "Adult" by default
  if (!passengerDob) {
    return "Adult";
  }

  const yearsDiff = calculateYearsDifference(
    passengerDob,
    formatDateSlashesToDashes(itineraryDate)
  );

  const isBabyOutInAgeRangeLimits = checkIfAgeInRange(yearsDiff, babyAgeRange);

  if (yearsDiff && isBabyOutInAgeRangeLimits) {
    return "Baby";
  }

  const isChildOutInAgeRangeLimits = checkIfAgeInRange(
    yearsDiff,
    childAgeRange
  );

  if (yearsDiff && isChildOutInAgeRangeLimits) {
    return "Child";
  }

  return "Adult";
};
