import { useEffect, useState } from "react";
import { getFromHub } from "../actions/networkActions";
import ItineraryEntry from "./ItineraryEntry";
import { updateOpenRefunds } from "../actions/cartActions";
import CruiseHeaderImage from "./CruiseHeaderImage";
import CruisesItineraryInfo from "./CruisesItineraryInfo";
import SvgIcon from "./atoms/SvgIcon";
import { collapseAll, expandAll } from "../actions/itineraryActions";
import useAppSelector from "../hooks/useAppSelector";
import {
  isShipNameInMusementMVP,
  isShipNameInOswMVP,
} from "config/configUtils";
import { getCruisesInfo } from "utils/cruiseUtils";
import { convertDMYtoYMD } from "utils/dateTimeUtils";
import { useCheckFeatureFlags } from "hooks/common/featureFlags/useCheckFeatureFlags";
import { useCheckConfigurationFlags } from "hooks/common/configuraitonFlags/useCheckConfigurationFlags";

const ItineraryOverview = () => {
  const cruiseDetails = useAppSelector((state) => state.cruises.cruiseDetails);
  const { isMxpEnabled } = useCheckFeatureFlags();
  const cruisesInfo = getCruisesInfo(cruiseDetails);
  const { isMxpDiningEnabledPerShip } = useCheckConfigurationFlags();

  const itineraryHasItems = useAppSelector(
    (state) =>
      state.openRefunds.nrEntries > 0 ||
      state.bookings.nrExcursions > 0 ||
      state.cart.nrExcursions > 0
  );
  const bookings = useAppSelector((state) => state.bookings);
  const openRefunds = useAppSelector((state) => state.openRefunds);
  const cartExcursions = useAppSelector((state) =>
    state.cart.excursions ? state.cart.excursions : {}
  );

  const cartMxpDining = useAppSelector((state) =>
    state.cart.mxp ? state.cart.mxp : {}
  );

  const cartTac = useAppSelector((state) => state.cart.tac);
  const cartOsw = useAppSelector((state) => state.cart.osw);
  const cartMusement = useAppSelector((state) => state.cart.musement);
  const itinerary = useAppSelector((state) => state.itinerary);

  const [nrRestaurants, setNrRestaurants] = useState(0);
  const [isTacBookingEnabled, setIsTacBookingEnabled] = useState(false);

  const updateNrRestaurants = (restaurants: any) => {
    setNrRestaurants(restaurants.length);
  };

  const updateTacConfig = (config: any) => {
    setIsTacBookingEnabled(config.diningBookingEnabled);
  };

  const calculateNrExcursions = () => {
    return itinerary.reduce((sum: number, itineraryElement: any) => {
      return sum + itineraryElement.numberOfBookableExcursions;
    }, 0);
  };

  const countItineraryItems = (itineraryId: string, itineraryDate: string) => {
    let count = 0;
    if (bookings.excursions.hasOwnProperty(itineraryId)) {
      count += Object.keys(bookings.excursions[itineraryId]).length;
    }

    if (cartExcursions.hasOwnProperty(itineraryId)) {
      count += Object.keys(cartExcursions[itineraryId]).length;
    }

    if (openRefunds.excursions.hasOwnProperty(itineraryId)) {
      count += Object.keys(openRefunds.excursions[itineraryId]).length;
    }

    if (bookings.tac.hasOwnProperty(itineraryDate)) {
      count += Object.keys(bookings.tac[itineraryDate]).length;
    }

    if (cartTac.hasOwnProperty(itineraryDate)) {
      count += Object.keys(cartTac[itineraryDate]).length;
    }

    if (bookings.osw.hasOwnProperty(itineraryDate)) {
      count += Object.keys(bookings.osw[itineraryDate]).length;
    }

    if (openRefunds.tac.hasOwnProperty(itineraryDate)) {
      count += Object.keys(openRefunds.tac[itineraryDate]).length;
    }

    if (cartOsw.hasOwnProperty(itineraryDate)) {
      count += Object.keys(cartOsw[itineraryDate]).length;
    }

    if (cartMusement.hasOwnProperty(itineraryDate)) {
      count += Object.keys(cartMusement[itineraryDate]).length;
    }

    if (cartMxpDining.hasOwnProperty(convertDMYtoYMD(itineraryDate))) {
      count += Object.keys(
        cartMxpDining[convertDMYtoYMD(itineraryDate)]
      ).length;
    }

    if (cartMxpDining.hasOwnProperty(convertDMYtoYMD(itineraryDate))) {
      count += Object.keys(
        cartMxpDining[convertDMYtoYMD(itineraryDate)]
      ).length;
    }

    if (bookings.musement.hasOwnProperty(itineraryDate)) {
      count += Object.keys(bookings.musement[itineraryDate]).length;
    }

    if (bookings.mxp.hasOwnProperty(convertDMYtoYMD(itineraryDate))) {
      count += Object.keys(bookings.mxp[convertDMYtoYMD(itineraryDate)]).length;
    }

    return count;
  };

  useEffect(() => {
    getFromHub(
      "prebooking/tac/templates/types/Restaurant",
      updateNrRestaurants
    );
    getFromHub("prebooking/tac/config", updateTacConfig);
    updateOpenRefunds();
  }, []);

  const lastDayIndex = itinerary.length - 1;
  const nrItems = itinerary.map((day: any) =>
    countItineraryItems(day.id, day.date)
  );

  const showExpandAll =
    itinerary.findIndex(
      (day: any, index: number) => day.expanded === false && nrItems[index] > 0
    ) > -1;

  const shouldShowOSW = isShipNameInOswMVP({ shipName: cruisesInfo.shipName });

  const shouldShowMxpDining = isMxpEnabled && isMxpDiningEnabledPerShip;

  const shouldShowMusementExcursions = isShipNameInMusementMVP({
    shipName: cruisesInfo.shipName,
  });

  return (
    <div>
      <div className="my-4 md:flex">
        <div className="md:w-2/3 md:pr-2">
          <CruiseHeaderImage />
        </div>
        <div className="pt-4 md:pt-0 md:w-1/3 md:pl-2 xl:flex xl:flex-col">
          <CruisesItineraryInfo
            nrExcursions={calculateNrExcursions()}
            nrRestaurants={nrRestaurants}
          />
        </div>
      </div>
      <div className="text-blue-dark my-8">
        <div className="flex">
          <h2 className="flex-grow">Your itinerary</h2>
          {itineraryHasItems && (
            <button
              className="text-blue"
              onClick={showExpandAll ? expandAll : collapseAll}
            >
              {showExpandAll ? "Expand all" : "Collapse all"}
              <SvgIcon
                name={showExpandAll ? "chevron-down" : "chevron-up"}
                className="w-6 h-6 inline ml-2"
              />
            </button>
          )}
        </div>

        <div className="my-4 text-blue-dark">
          {itinerary.map((itineraryElement: any, index: number) => (
            <ItineraryEntry
              firstDay={index === 0}
              lastDay={index === lastDayIndex}
              key={itineraryElement.id}
              nrItems={nrItems[index]}
              shouldShowOSW={shouldShowOSW}
              shouldShowMxpDining={shouldShowMxpDining}
              shouldShowMusementExcursions={shouldShowMusementExcursions}
              isTacBookingEnabled={isTacBookingEnabled}
              {...itineraryElement}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ItineraryOverview;
