import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import RadioButton from "components/Buttons/RadioButton/RadioButton";
import Checkbox from "components/Checkbox";
import SvgIcon from "components/atoms/SvgIcon";
import MusementExcursionEntry from "./MusementExcursionEntry";
import {
  IMusementTaxomomy,
  IMusementTaxomomyData,
} from "interfaces/Musement/ITaxonomy";
import { convertDurationToMinutes } from "utils/dateTimeUtils";
import { IItineraryExcursion } from "interfaces/Musement/IItineraryExcursion";
import { isEmpty, isEqual } from "lodash";

type Props = {
  itineraryId: string;
  excursions: IItineraryExcursion[];
  excrusionFilterOptions: IMusementTaxomomyData;
};

type ExcursionFilterProps = PropsWithChildren & {
  filterBy: string;
  closeFilter: () => void;
  clearFilter: () => void;
};

type FilterHeaderProps = {
  openFilter: string;
  filterId: string;
  toggleVisibility: (filterId: string) => void;
  filterName: string;
};

type ExcursionDurationFilterProps = {
  options: any;
  selectedRadio: any;
  handleRadioChange: (option: any) => void;
};

interface ITaxonomyFilterTypeOption {
  label: string;
  activityUuids: string[];
  selected: boolean;
  disabled: boolean;
}

interface ITaxonomyFilterTypeOptions {
  [key: string]: ITaxonomyFilterTypeOption;
}

type ExcursionTypeFilterProps = {
  excursionTypes: ITaxonomyFilterTypeOptions;
  handleCheckboxChange: (type: string) => void;
};

const DURATION_FILTERS = {
  options: [
    {
      label: "all",
      minDuration: 0,
      maxDuration: 1440,
      disabled: false,
    },
    {
      label: "2h or less",
      minDuration: 0,
      maxDuration: 120,
      disabled: false,
    },
    {
      label: "2h to 5h",
      minDuration: 120,
      maxDuration: 300,
      disabled: false,
    },
    {
      label: "5h or more",
      minDuration: 300,
      maxDuration: 1440,
      disabled: false,
    },
  ],
  selectedOption: "all",
  minDuration: 0,
  maxDuration: 1440,
};

const MusementFilteredExcursions: FC<Props> = ({
  itineraryId,
  excursions,
  excrusionFilterOptions,
}) => {
  const innerExcursionsRef = useRef([...excursions]);
  const [openFilter, setOpenFilter] = useState("");
  const [
    filteredExcursionsWithTaxonomies,
    setFilteredExcursionsWithTaxonomies,
  ] = useState(excursions);
  const [typeFilterOptions, setTypeFilterOptions] =
    useState<ITaxonomyFilterTypeOptions>({});
  const [durationFilter, setDurationFilter] = useState(DURATION_FILTERS);

  const toggleFilterVisibility = useCallback(
    (filterName: string) => {
      if (filterName === openFilter) {
        setOpenFilter("");
      } else {
        setOpenFilter(filterName);
      }
    },
    [openFilter]
  );

  const matchesDurationCriteria = useCallback(
    (excursion: IItineraryExcursion) => {
      if (!excursion.duration_range) {
        return false;
      }

      const minutes = convertDurationToMinutes(excursion.duration_range.max);

      //check if excursion duration is between minDuration and maxDuration
      return !(
        minutes > durationFilter.maxDuration ||
        minutes < durationFilter.minDuration
      );
    },
    [durationFilter.maxDuration, durationFilter.minDuration]
  );

  const toggleTypeFilterOption = useCallback((type: string) => {
    setTypeFilterOptions((prevState: ITaxonomyFilterTypeOptions) => {
      return {
        ...prevState,
        [type]: {
          ...prevState[type],
          selected: !prevState[type].selected,
        },
      };
    });
  }, []);

  const handleDurationFilterChange = useCallback(
    (selectedOption: any) => {
      setDurationFilter({
        ...durationFilter,
        selectedOption: selectedOption.label,
        minDuration: selectedOption.minDuration,
        maxDuration: selectedOption.maxDuration,
      });
    },
    [durationFilter]
  );

  const clearAllFilters = useCallback(() => {
    for (const type in typeFilterOptions) {
      typeFilterOptions[type].selected = false;
      typeFilterOptions[type].disabled = false;
    }

    setTypeFilterOptions((prevState: ITaxonomyFilterTypeOptions) => ({
      ...prevState,
      ...typeFilterOptions,
    }));

    setDurationFilter({
      ...durationFilter,
      selectedOption: "all",
      minDuration: 0,
      maxDuration: 1440,
    });
  }, [durationFilter, typeFilterOptions]);

  const filterExcursions = useCallback(() => {
    const selectedFilters = Object?.values(typeFilterOptions)?.filter(
      (taxonomyFilterOption: ITaxonomyFilterTypeOption) =>
        taxonomyFilterOption?.selected === true
    );

    // Filter By Type
    let filteredData = innerExcursionsRef.current?.filter(
      (excursion: IItineraryExcursion) =>
        selectedFilters?.some((filter) =>
          filter?.activityUuids?.includes(excursion.uuid)
        )
    );

    // If Filter By Type Retuns Empty Array
    // Reset Data to Excursions
    if (filteredData?.length === 0) {
      filteredData = innerExcursionsRef.current.slice();
    }

    //Filter By Duration
    filteredData = filteredData?.filter((excursion: any) => {
      return matchesDurationCriteria(excursion);
    });

    setFilteredExcursionsWithTaxonomies((prevFilteredExcursions) => {
      if (!isEqual(prevFilteredExcursions, filteredData)) {
        return filteredData;
      }

      return prevFilteredExcursions;
    });
  }, [matchesDurationCriteria, typeFilterOptions]);

  // initial setup of innner component's taxonomies filters
  useEffect(() => {
    if (
      excrusionFilterOptions &&
      Object.keys(excrusionFilterOptions).length > 0 &&
      isEmpty(typeFilterOptions)
    ) {
      const uniqueTaxonomiesNames: string[] = [];
      const uniqueTaxonomiesTypesOptions: ITaxonomyFilterTypeOptions = {};

      Object.values(excrusionFilterOptions).forEach(
        (taxonomyGroup: IMusementTaxomomy[]) => {
          taxonomyGroup.forEach((taxonomyEl: IMusementTaxomomy) => {
            if (!uniqueTaxonomiesNames.includes(taxonomyEl.name)) {
              uniqueTaxonomiesNames.push(taxonomyEl.name);
              uniqueTaxonomiesTypesOptions[taxonomyEl.name] = {
                label: taxonomyEl.name,
                activityUuids: [taxonomyEl.activityUuid],
                selected: false,
                disabled: false,
              };
            } else {
              uniqueTaxonomiesTypesOptions[taxonomyEl.name].activityUuids.push(
                taxonomyEl.activityUuid
              );
            }
          });
        }
      );

      for (const taxonomy of Object.values(uniqueTaxonomiesTypesOptions)) {
        taxonomy?.activityUuids?.forEach((taxonomyActivityUuid: string) => {
          const foundExcursion = innerExcursionsRef.current?.find(
            (excursion: IItineraryExcursion) =>
              excursion?.uuid === taxonomyActivityUuid
          );

          if (foundExcursion) {
            if (
              foundExcursion?.taxonomies &&
              !foundExcursion?.taxonomies?.some(
                (foundTaxonomy: ITaxonomyFilterTypeOption) =>
                  foundTaxonomy.label === taxonomy.label
              )
            ) {
              foundExcursion.taxonomies?.push(taxonomy);
            } else {
              foundExcursion.taxonomies = [taxonomy];
            }
          }
        });
      }

      setTypeFilterOptions((prevState: ITaxonomyFilterTypeOptions) => {
        if (!isEqual(prevState, uniqueTaxonomiesTypesOptions)) {
          return uniqueTaxonomiesTypesOptions;
        }
        return prevState;
      });
    }
  }, [excrusionFilterOptions, typeFilterOptions]);

  useEffect(() => {
    filterExcursions();
  }, [filterExcursions]);

  return (
    <div>
      {/*filter headers*/}
      <div className="excursion-filters-container">
        <div className="container mx-auto flex">
          <FilterHeader
            filterId="type"
            filterName="Excursion type"
            openFilter={openFilter}
            toggleVisibility={toggleFilterVisibility}
          />

          <FilterHeader
            filterId="duration"
            filterName="Duration"
            openFilter={openFilter}
            toggleVisibility={toggleFilterVisibility}
          />
        </div>
      </div>

      {/*filter content*/}
      <div>
        {/*type filter*/}
        {openFilter === "type" && (
          <ExcursionFilter
            filterBy="Excursion type"
            closeFilter={() => toggleFilterVisibility("type")}
            clearFilter={clearAllFilters}
          >
            <ExcursionTypeFilter
              excursionTypes={typeFilterOptions}
              handleCheckboxChange={toggleTypeFilterOption}
            />
          </ExcursionFilter>
        )}
        {/*duration filter*/}
        {openFilter === "duration" && (
          <ExcursionFilter
            filterBy="Excursion duration"
            closeFilter={() => toggleFilterVisibility("duration")}
            clearFilter={clearAllFilters}
          >
            <ExcursionDurationFilter
              options={durationFilter.options}
              handleRadioChange={handleDurationFilterChange}
              selectedRadio={durationFilter.selectedOption}
            />
          </ExcursionFilter>
        )}
      </div>

      {/*excursions*/}
      <div className="bg-blue-lightest">
        <div className="flex flex-col gap-8 container mx-auto py-8">
          {filteredExcursionsWithTaxonomies.map((excursion) => (
            <MusementExcursionEntry
              itineraryId={itineraryId}
              key={excursion.uuid}
              {...excursion}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const FilterHeader: FC<FilterHeaderProps> = ({
  openFilter,
  filterName,
  filterId,
  toggleVisibility,
}) => {
  const filterOpen = openFilter === filterId;

  return (
    <div
      className={
        "pt-4 flex-1 text-center uppercase border-l-2 border-r border-white cursor-pointer select-none" +
        (filterOpen ? " text-blue bg-white" : " text-white")
      }
      onClick={() => toggleVisibility(filterId)}
    >
      <div className="mb-4">{filterName}</div>
      <div className="font-bold">{filterOpen ? " \uFE3F " : "\uFE40"}</div>
    </div>
  );
};

const ExcursionFilter: FC<ExcursionFilterProps> = ({
  filterBy,
  closeFilter,
  clearFilter,
  children,
}) => {
  return (
    <div>
      {/*filter header*/}
      <div className="container mx-auto">
        <div className=" py-4 border-b">
          Filter by <span className="font-bold">{filterBy}</span>
          <div
            className="float-right text-blue-light cursor-pointer"
            onClick={closeFilter}
          >
            <SvgIcon name="x" className="w-4 h-4 inline" />
          </div>
        </div>
      </div>
      {/*filter content*/}
      <div className="container mx-auto">
        <div className="px-4">{children}</div>
      </div>
      {/*filter footer*/}
      <div className="bg-blue-lightest">
        <div className="container mx-auto py-4 text-right">
          <div className="uppercase cursor-pointer" onClick={clearFilter}>
            clear
          </div>
        </div>
      </div>
    </div>
  );
};

const ExcursionTypeFilter: FC<ExcursionTypeFilterProps> = ({
  excursionTypes,
  handleCheckboxChange,
}) => {
  return (
    <div>
      <ul className="list-reset flex flex-wrap">
        {Object.keys(excursionTypes).map((type) => (
          <li className="my-2 h-6 w-full sm:w-1/3" key={type}>
            <Checkbox
              label={type}
              checked={excursionTypes[type].selected}
              disabled={excursionTypes[type].disabled}
              handleCheckboxChange={() => handleCheckboxChange(type)}
              key={type}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

const ExcursionDurationFilter: FC<ExcursionDurationFilterProps> = ({
  options,
  handleRadioChange,
  selectedRadio,
}) => {
  return (
    <div>
      <ul className="list-reset sm:flex sm:justify-around">
        {options.map((option: any, index: number) => (
          <li className="my-2 h-6" key={index}>
            <RadioButton
              label={option.label}
              _onChange={() => handleRadioChange(option)}
              isSelected={selectedRadio === option.label}
              isDisabled={option.disabled}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MusementFilteredExcursions;
