import { MouseEvent, useCallback, useEffect, useState } from "react";
import ContentDividerWithIcon from "../../components/ContentDividerWithIcon";
import Checkbox from "../../components/Checkbox";
import CartSumInfo from "../../components/CartSumInfo";
import CheckoutSubheader from "../../components/CheckoutSubheader";
import { getFromHub, postToHub } from "../../actions/networkActions";
import If from "../../components/molecules/IF";
import TextInputWithLabel from "../../components/TextInputWithLabel";
import {
  orderCart,
  updateBookings,
  updateOswBookings,
  setChosenGroupToPay,
  updateMusementBookings,
  updateMxpBookings,
} from "../../actions/cartActions";
import { imagePath } from "../../utils/imageUtils";
import { savePayment } from "../../actions/paymentActions";
import { formatPortTimeTandC } from "../../utils/dateTimeUtils";
import browserHistory from "../../utils/history";
import CartElementsCheckoutCombined from "../../components/cartElements/CartElementsCheckoutCombined";
import ErrorModal from "../../components/modals/ErrorModal";
import { isEmail } from "../../utils/validationUtils";
import BackLink from "../../components/molecules/BackLink";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import CartElementsCheckoutCombinedOSW from "components/cartElements/CartElementsOswTreatments/CartElementsCheckoutCombinedOSW";
import SelectableContentDividerWithIcon from "components/SelectableContentDividerWithIcon";
import { usePostInitPaymentOSW } from "./usePostInitPaymentOSW";
import CartElementsCheckoutCombinedMusement from "modules/musement/cartElements/CartElementsCheckoutCombinedMusement";
import { usePostInitPaymentMusement } from "./usePostInitPaymentMusement";
import { useUpdateCart } from "actions/useUpdateCart";
import { usePostInitPaymentAgencyMusement } from "./usePostInitPaymentAgencyMusement";
import CartExpirationTimeWarningMessage from "components/CartExpirationTimeWarningMessage/CartExpirationTimeWarningMessage";
import CartElementsCheckoutCombinedMXP from "components/cartElements/mxp/checkout/CartElementsCheckoutCombinedMXP";
import { usePostInitPaymentMxp } from "./usePostInitPaymentMxp";
import { usePostInitPaymentAgencyMxpDining } from "./usePostInitPaymentAgencyMxpDining";

enum CartToPayEnum {
  ExcursionAndDinning = "ExcursionAndDinning",
  OSW = "OSW",
  MusementExcursions = "MusementExcursions",
  MxpDining = "MxpDining",
}

const PassengerDetails = () => {
  const { postInitPaymentOSW } = usePostInitPaymentOSW();
  const { postInitPaymentMxp } = usePostInitPaymentMxp();

  const { postInitPaymentAgencyMusement } = usePostInitPaymentAgencyMusement();
  const { postInitPaymentAgencyMxpDining } =
    usePostInitPaymentAgencyMxpDining();

  const { postInitPaymentMusement } = usePostInitPaymentMusement();
  const { updateCart } = useUpdateCart();

  const dispatch = useAppDispatch();

  const isAgency = useAppSelector((state) => state.agency.isAuthenticated);
  const totalSum = useAppSelector((state) => state.cart.totalPrice);
  const chosenGroupToPay = useAppSelector(
    (state) => state.cart.chosenGroupToPay
  );

  const cruiseStartDate = useAppSelector((state) =>
    state.cruises ? state.cruises.cruiseStartDate : null
  );

  const excursions = useAppSelector((state) => state.cart.excursions);
  const mxpEntries = useAppSelector((state) => state.cart.mxp);
  const tacEntries = useAppSelector((state) => state.cart.tac);
  const oswEntries = useAppSelector((state) => state.cart.osw);
  const musementEntries = useAppSelector((state) => state.cart.musement);

  const shouldShowExcursionEntries = useCallback(
    () =>
      (excursions && Object.keys(excursions)?.length > 0) ||
      (tacEntries && Object.keys(tacEntries)?.length > 0),
    [excursions, tacEntries]
  );

  const shouldShowMxpDinEntries = useCallback(
    () => mxpEntries && Object.keys(mxpEntries)?.length > 0,
    [mxpEntries]
  );

  const shouldShowOSWEntries = useCallback(
    () => oswEntries && Object.keys(oswEntries)?.length > 0,
    [oswEntries]
  );

  const shouldShowMusementEntries = useCallback(
    () => musementEntries && Object.keys(musementEntries)?.length > 0,
    [musementEntries]
  );

  const [paymentReference, setPaymentReference] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [importantInfoChecked, setImportantInfoChecked] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isContinueEnabled, setIsContinueEnabled] = useState<boolean>(false);
  const [isPaymentEnabled, setIsPaymentEnabled] = useState(null);
  const [continueButtonDisabled, setContinueButtonDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [paymentGateway, setPaymentGateway] = useState(null);
  const [tokenError, setTokenError] = useState<string>("");

  useEffect(() => {
    updateCart();
    getFromHub("payment/config", updatePaymentConfig);
  }, []);

  const toggleImportantInfoCheckbox = () => {
    setImportantInfoChecked(!importantInfoChecked);
  };

  const updatePaymentConfig = (response: any) => {
    setIsPaymentEnabled(response.paymentEnabled);
    setPaymentGateway(response.paymentGateway);
  };

  const processForm = (e: MouseEvent) => {
    e.preventDefault();
    if (continueButtonDisabled) {
      return;
    }

    let tempErrors: any = {};

    if (!isEmail(email)) {
      tempErrors.email = "Please enter your email address";
    }

    if (email !== confirmEmail) {
      tempErrors.confirm = "Email addresses must match";
    }

    if (!importantInfoChecked) {
      tempErrors.importantInfo =
        "Please read and agree to the Terms & Conditions";
    }

    if (isAgency && !paymentReference) {
      tempErrors.paymentReference =
        "Please enter the payment authorisation code";
    }

    const regex = RegExp("^[a-z0-9]{4,8}$", "i");
    if (isAgency && !!paymentReference && !regex.test(paymentReference)) {
      tempErrors.paymentReference =
        "Please enter a valid reference containing between 4-8 characters, and no punctuation or special characters.";
    }

    if (Object.keys(tempErrors).length > 0) {
      setErrors(tempErrors);
      return;
    }

    setContinueButtonDisabled(true);
    setErrors({});
    setIsContinueEnabled(true);
  };

  const retrieveGppOSWPaymentToken = useCallback(async () => {
    const clientDomainUrl =
      window.location.protocol + "//" + window.location.host;

    let paymentData: any = {
      clientDomainUrl,
      confirmationMail: confirmEmail,
      paymentReference: paymentReference,
    };

    const { responseData, error } = await postInitPaymentOSW({
      tenantReference: paymentReference,
      emailAddress: confirmEmail,
      currency: "GBP",
    });

    if (mobileNumber) {
      paymentData.mobileNumber = mobileNumber;
    }

    if (responseData.token) {
      paymentData.paymentReference = responseData.token;
      paymentData.transactionId = responseData.transactionId;
      paymentData.paymentType = "osw";
      dispatch(savePayment(paymentData));

      browserHistory.push("/payment", {
        paymentReference: responseData.token,
        mfaSource: responseData.mfaSource,
      });
    }

    if (error) {
      console.error(error);
      setTokenError(
        "An error has occurred! Please check your network connection."
      );
      setContinueButtonDisabled(false);
    }
  }, [
    confirmEmail,
    dispatch,
    mobileNumber,
    paymentReference,
    postInitPaymentOSW,
  ]);

  const retrieveGppMxpPaymentToken = useCallback(async () => {
    const clientDomainUrl =
      window.location.protocol + "//" + window.location.host;

    let paymentData: any = {
      clientDomainUrl,
      confirmationMail: confirmEmail,
      paymentReference: paymentReference,
    };

    const { responseData, error } = await postInitPaymentMxp({
      tenantReference: paymentReference,
      emailAddress: confirmEmail,
    });

    if (mobileNumber) {
      paymentData.mobileNumber = mobileNumber;
    }

    if (responseData.token) {
      paymentData.paymentReference = responseData.token;
      paymentData.transactionId = responseData.transactionId;
      paymentData.paymentType = "mxp";
      dispatch(savePayment(paymentData));

      browserHistory.push("/payment", {
        paymentReference: responseData.token,
        mfaSource: responseData.mfaSource,
      });
    }

    if (error) {
      console.error(error);
      setTokenError(
        "An error has occurred! Please check your network connection."
      );
      setContinueButtonDisabled(false);
    }
  }, [
    confirmEmail,
    dispatch,
    mobileNumber,
    paymentReference,
    postInitPaymentMxp,
  ]);

  const retrieveGppMusementPaymentToken = useCallback(async () => {
    const clientDomainUrl =
      window.location.protocol + "//" + window.location.host;

    let paymentData: any = {
      clientDomainUrl,
      confirmationMail: confirmEmail,
      paymentReference: paymentReference,
    };

    const { responseData, error } = await postInitPaymentMusement({
      tenantReference: paymentReference,
      emailAddress: confirmEmail,
    });

    if (mobileNumber) {
      paymentData.mobileNumber = mobileNumber;
    }

    if (responseData.token) {
      paymentData.paymentReference = responseData.token;
      paymentData.transactionId = responseData.transactionId;
      paymentData.paymentType = "musement";
      dispatch(savePayment(paymentData));

      browserHistory.push("/payment", {
        paymentReference: responseData.token,
        mfaSource: responseData.mfaSource,
      });
    }

    if (error) {
      console.error(error);
      setTokenError(
        "An error has occurred! Please check your network connection."
      );
      setContinueButtonDisabled(false);
    }
  }, [
    confirmEmail,
    dispatch,
    mobileNumber,
    paymentReference,
    postInitPaymentMusement,
  ]);

  const retrieveGppPaymentToken = useCallback(() => {
    const clientDomainUrl =
      window.location.protocol + "//" + window.location.host;
    let paymentData: any = {
      clientDomainUrl,
      confirmationMail: confirmEmail,
      paymentReference: paymentReference,
    };

    if (mobileNumber) {
      paymentData.mobileNumber = mobileNumber;
    }

    postToHub(
      "payment/token",
      paymentData,
      (response: any) => {
        if (response.token) {
          paymentData.paymentReference = response.token;
          paymentData.transactionId = response.transactionId;
          paymentData.paymentType = "dinning";
          dispatch(savePayment(paymentData));

          browserHistory.push("/payment", {
            paymentReference: response.token,
            mfaSource: response.mfaSource,
          });
        }
      },
      (error: any) => {
        console.error(error);
        setTokenError(
          "An error has occurred! Please check your network connection."
        );
        setContinueButtonDisabled(false);
      }
    );
  }, [confirmEmail, dispatch, mobileNumber, paymentReference]);

  const onOrderCompleted = useCallback(
    (response: any) => {
      if (isAgency || isPaymentEnabled === false) {
        browserHistory.push("/confirmation/b2b", {
          bookingNr: response.groupReference,
          logoutUserOnMount: isAgency,
          confirmationMail: email,
        });
      } else {
        setContinueButtonDisabled(false);
        updateCart();
        updateBookings();
        updateOswBookings();
        updateMusementBookings();
        updateMxpBookings();
      }
    },
    [email, isAgency, isPaymentEnabled, updateCart]
  );

  const _order = useCallback(() => {
    let payload = {
      confirmationMail: confirmEmail,
      paymentReference: paymentReference,
      mobileNumber: mobileNumber,
    };

    orderCart(payload, onOrderCompleted, onOrderError);
  }, [confirmEmail, mobileNumber, onOrderCompleted, paymentReference]);

  const onOrderError = (response: any) => {
    const errorMessage =
      response.response?.data === "Shoppingcart NOT bookable"
        ? "Some items in the cart are no longer bookable at this time, please make sure to remove the sold out items"
        : "There has been an error when ordering, please try again or seek support if problem persists";
    setContinueButtonDisabled(false);
    setErrorMessage(errorMessage);
  };

  const handleCheckboxChange = (selectedEnum: CartToPayEnum) => {
    if (CartToPayEnum.ExcursionAndDinning === selectedEnum) {
      setChosenGroupToPay({
        chosenGroupToPay: CartToPayEnum.ExcursionAndDinning,
      });
    } else if (CartToPayEnum.OSW === selectedEnum) {
      setChosenGroupToPay({ chosenGroupToPay: CartToPayEnum.OSW });
    } else if (CartToPayEnum.MxpDining === selectedEnum) {
      setChosenGroupToPay({ chosenGroupToPay: CartToPayEnum.MxpDining });
    } else {
      setChosenGroupToPay({
        chosenGroupToPay: CartToPayEnum.MusementExcursions,
      });
    }
  };

  // G.k. TODO get the total price from BE and remove the function from PaymentConfirmationOverview also
  const getCartSumBySelectedCartForMusement = useCallback(() => {
    let totalPriceReturn = 0;

    for (let [, musementEntriesArray] of Object?.entries(musementEntries)) {
      let entrySum = 0;
      musementEntriesArray?.forEach((entryArrays) => {
        entryArrays?.forEach((entry) => {
          entrySum += entry?.totalPrice;
        });
      });

      totalPriceReturn += entrySum;
    }
    return totalPriceReturn;
  }, [musementEntries]);

  // G.k. TODO get the total price from BE and remove the function from PaymentConfirmationOverview also
  const getCartSumBySelectedCartForMxpDining = useCallback(() => {
    let totalPriceReturn = 0;

    for (let [, v] of Object.entries(mxpEntries)) {
      totalPriceReturn += v?.reduce((sum: number, currentValue) => {
        return sum + currentValue?.totalAmount;
      }, 0);
    }

    return totalPriceReturn;
  }, [mxpEntries]);

  // G.k. TODO get the total price from BE and remove the function from PaymentConfirmationOverview also
  const getCartSumBySelectedCartForOsw = useCallback(() => {
    let oswPrice = 0;

    for (let [, v] of Object.entries(oswEntries)) {
      oswPrice += v?.reduce((sum, currentValue) => {
        return sum + currentValue?.price;
      }, 0);
    }

    return oswPrice;
  }, [oswEntries]);

  const getCartSumBySelectedCart = useCallback(() => {
    if (chosenGroupToPay === CartToPayEnum.OSW) {
      return getCartSumBySelectedCartForOsw();
    } else if (chosenGroupToPay === CartToPayEnum.MusementExcursions) {
      return getCartSumBySelectedCartForMusement();
    } else if (chosenGroupToPay === CartToPayEnum.MxpDining) {
      return getCartSumBySelectedCartForMxpDining();
    } else {
      return (
        totalSum -
        getCartSumBySelectedCartForOsw() -
        getCartSumBySelectedCartForMusement() -
        getCartSumBySelectedCartForMxpDining()
      );
    }
  }, [
    chosenGroupToPay,
    getCartSumBySelectedCartForMusement,
    getCartSumBySelectedCartForMxpDining,
    getCartSumBySelectedCartForOsw,
    totalSum,
  ]);

  const _orderAgencyMM = useCallback(async () => {
    let payload = {
      amount: getCartSumBySelectedCart(),
      currency: "GBP",
      tenantReference: paymentReference,
      emailAddress: confirmEmail,
      mobileNumber: mobileNumber,
    };

    const { responseData, error } = await postInitPaymentAgencyMusement(
      payload
    );

    if (error) {
      browserHistory.push("/book/failure");
    }

    if (responseData?.bookingReference) {
      browserHistory.push("/confirmation/b2b", {
        bookingNr: responseData?.bookingReference,
      });
    }
  }, [
    confirmEmail,
    getCartSumBySelectedCart,
    mobileNumber,
    paymentReference,
    postInitPaymentAgencyMusement,
  ]);

  const _orderAgencyMxpDining = useCallback(async () => {
    let payload = {
      amount: getCartSumBySelectedCart(),
      currency: "GBP",
      tenantReference: paymentReference,
      emailAddress: confirmEmail,
      mobileNumber: mobileNumber,
    };

    const { responseData, error } = await postInitPaymentAgencyMxpDining(
      payload
    );

    if (error) {
      browserHistory.push("/book/failure");
    }

    if (responseData?.bookingReference) {
      browserHistory.push("/confirmation/b2b", {
        bookingNr: responseData?.bookingReference,
      });
    }
  }, [
    confirmEmail,
    getCartSumBySelectedCart,
    mobileNumber,
    paymentReference,
    postInitPaymentAgencyMxpDining,
  ]);

  useEffect(() => {
    if (isContinueEnabled) {
      if (
        (isAgency || isPaymentEnabled === false) &&
        CartToPayEnum.MusementExcursions === chosenGroupToPay
      ) {
        _orderAgencyMM();
      } else if (
        (isAgency || isPaymentEnabled === false) &&
        CartToPayEnum.MxpDining === chosenGroupToPay
      ) {
        _orderAgencyMxpDining();
      } else if (isAgency || isPaymentEnabled === false) {
        _order();
      } else {
        if (paymentGateway === "GPP") {
          if (CartToPayEnum.ExcursionAndDinning === chosenGroupToPay) {
            retrieveGppPaymentToken();
          }

          if (CartToPayEnum.OSW === chosenGroupToPay) {
            retrieveGppOSWPaymentToken();
          }

          if (CartToPayEnum.MxpDining === chosenGroupToPay) {
            retrieveGppMxpPaymentToken();
          }

          if (CartToPayEnum.MusementExcursions === chosenGroupToPay) {
            retrieveGppMusementPaymentToken();
          }
        }
      }
    }
  }, [
    _order,
    _orderAgencyMM,
    _orderAgencyMxpDining,
    chosenGroupToPay,
    isAgency,
    isContinueEnabled,
    isPaymentEnabled,
    paymentGateway,
    retrieveGppMusementPaymentToken,
    retrieveGppMxpPaymentToken,
    retrieveGppOSWPaymentToken,
    retrieveGppPaymentToken,
  ]);

  useEffect(() => {
    if (shouldShowExcursionEntries()) {
      setChosenGroupToPay({
        chosenGroupToPay: CartToPayEnum.ExcursionAndDinning,
      });
    } else if (shouldShowOSWEntries()) {
      setChosenGroupToPay({ chosenGroupToPay: CartToPayEnum.OSW });
    } else if (shouldShowMxpDinEntries()) {
      setChosenGroupToPay({ chosenGroupToPay: CartToPayEnum.MxpDining });
    } else {
      setChosenGroupToPay({
        chosenGroupToPay: CartToPayEnum.MusementExcursions,
      });
    }
  }, [
    shouldShowExcursionEntries,
    shouldShowMxpDinEntries,
    shouldShowOSWEntries,
  ]);

  if (isPaymentEnabled === null) {
    return <div className="container mx-auto mt-12">Loading...</div>;
  }

  let tAndCUrl = "https://www.tui.co.uk/destinations/booking/tandc?ptype=PDP";
  if (cruiseStartDate) {
    const bDate = formatPortTimeTandC(cruiseStartDate);
    tAndCUrl =
      "https://www.tui.co.uk/destinations/booking/tandc?bdate=" +
      bDate +
      "&bcode=TH&ptype=CR";
  }

  return (
    <div>
      <div className="bg-blue text-white uppercase">
        <CheckoutSubheader active="summary" hideOnMobile={false} />
      </div>

      <div className="container px-4 mx-auto mt-4">
        <BackLink to="/itinerary" text="Cruise Planner" />

        <h1>Summary</h1>

        <div className="mb-4">
          <CartExpirationTimeWarningMessage />
        </div>

        {/* Only if Excursions, Dinning and OSW are shown at the same time */}
        {shouldShowExcursionEntries() && shouldShowOSWEntries() && (
          <div className="py-2 px-4 text-black bg-yellow">
            <h3 className="text-xl my-2">IMPORTANT INFORMATION</h3>
            <p>
              Just so you know, you'll need to check out and make separate
              payments for your spa bookings and your dining or excursion
              bookings.
            </p>
          </div>
        )}

        {/* Only for Excursions and Dinning */}
        {shouldShowExcursionEntries() && (
          <div className="shadow">
            <SelectableContentDividerWithIcon
              iconAltText=""
              iconSrc={imagePath("shoppingcart.svg")}
              title="Your cruise dinning items"
              checked={CartToPayEnum.ExcursionAndDinning === chosenGroupToPay}
              handleCheckboxChange={() =>
                handleCheckboxChange(CartToPayEnum.ExcursionAndDinning)
              }
            />

            <CartElementsCheckoutCombined />
          </div>
        )}

        {/* Only for Excursions and Dinning */}
        {shouldShowMxpDinEntries() && (
          <div className="shadow">
            <SelectableContentDividerWithIcon
              iconAltText=""
              iconSrc={imagePath("shoppingcart.svg")}
              title="Your cruise dinning items"
              checked={CartToPayEnum.MxpDining === chosenGroupToPay}
              handleCheckboxChange={() =>
                handleCheckboxChange(CartToPayEnum.MxpDining)
              }
            />

            <CartElementsCheckoutCombinedMXP />
          </div>
        )}

        {/* Only for OSW */}
        {shouldShowOSWEntries() && (
          <div className="shadow">
            <SelectableContentDividerWithIcon
              iconAltText=""
              iconSrc={imagePath("shoppingcart.svg")}
              title="Your cruise spa treatment items"
              checked={CartToPayEnum.OSW === chosenGroupToPay}
              handleCheckboxChange={() =>
                handleCheckboxChange(CartToPayEnum.OSW)
              }
            />

            <CartElementsCheckoutCombinedOSW />
          </div>
        )}

        {/* Only for Musement */}
        {shouldShowMusementEntries() && (
          <div className="shadow">
            <SelectableContentDividerWithIcon
              iconAltText=""
              iconSrc={imagePath("shoppingcart.svg")}
              title="Your cruise excursion items"
              checked={CartToPayEnum.MusementExcursions === chosenGroupToPay}
              handleCheckboxChange={() =>
                handleCheckboxChange(CartToPayEnum.MusementExcursions)
              }
            />

            <CartElementsCheckoutCombinedMusement />
          </div>
        )}

        <form className="mt-8">
          <If test={isAgency}>
            <ContentDividerWithIcon
              iconAltText="tui logo"
              iconSrc={imagePath("tuilogo.svg")}
              title="Agency Information"
            />

            <div className="px-2 sm:px-4 mb-8">
              <div className="my-4">
                <TextInputWithLabel
                  label="Payment authorisation code"
                  type="text"
                  value={paymentReference}
                  _onChange={(value: any) => {
                    setPaymentReference(value);
                  }}
                  placeholder=""
                  errorMessage={errors.paymentReference}
                />
              </div>
            </div>
          </If>

          <ContentDividerWithIcon
            iconAltText="email at sign"
            iconSrc={imagePath("at-symbol.svg")}
            title="Contact details"
          />
          {/*contact fields*/}
          <div className="px-2 sm:px-4 mb-8">
            <div className="my-4">
              <TextInputWithLabel
                label={isAgency ? "Customer Email Address" : "Email Address"}
                type="text"
                value={email}
                _onChange={(value: any) => setEmail(value)}
                placeholder=""
                errorMessage={errors.email}
              />
            </div>

            <div className="my-4">
              <TextInputWithLabel
                label={
                  isAgency
                    ? "Confirm Customer Email Address"
                    : "Confirm Email Address"
                }
                type="text"
                value={confirmEmail}
                _onChange={(value: any) => {
                  setConfirmEmail(value);
                }}
                placeholder=""
                errorMessage={errors.confirm}
              />
            </div>

            <div className="my-4 text-grey-darker">
              <TextInputWithLabel
                label={
                  isAgency
                    ? "CUSTOMER MOBILE NUMBER (optional)"
                    : "MOBILE NUMBER (optional)"
                }
                type="text"
                value={mobileNumber}
                _onChange={(value: any) => {
                  setMobileNumber(value);
                }}
                placeholder=""
              />
              Please enter a mobile number if you have one so we can keep you
              informed whilst you are on holiday
            </div>
          </div>

          {/* terms & conditions - now shown for everyone, but with different texts for agency */}
          <ContentDividerWithIcon
            iconAltText="info icon"
            iconSrc={imagePath("infoicon.svg")}
            title="Terms & conditions"
          />
          <div className="my-4 px-2 sm:px-4">
            {/* instructions text only shown for agency */}
            {isAgency && (
              <div className="my-4">
                Please read our{" "}
                <a
                  className="text-blue hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.tui.co.uk/destinations/info/privacy-policy"
                >
                  Privacy Policy
                </a>{" "}
                and confirm you agree to our use of your information by ticking
                the box below.
              </div>
            )}

            <div className="bg-grey-lighter py-4 px-2 sm:px-4">
              <div
                className={`bg-white p-4 ${
                  errors.importantInfo ? "border border-red" : ""
                }`}
              >
                <Checkbox
                  checked={importantInfoChecked}
                  value="tos"
                  handleCheckboxChange={() => toggleImportantInfoCheckbox()}
                >
                  {/* checkbox text with links for agency */}
                  {isAgency && (
                    <>
                      I have read and accept{" "}
                      <a
                        className="text-blue hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={tAndCUrl}
                      >
                        Our/Your Agreement Booking Conditions
                      </a>
                      , Website{" "}
                      <a
                        className="text-blue hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.tui.co.uk/destinations/info/website-terms-and-conditions?_ga=2.124330954.1309004715.1531228072-1069089380.1522339950"
                      >
                        Terms & Conditions
                      </a>{" "}
                      and Cruise Control{" "}
                      <a
                        className="text-blue hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.tui.co.uk/destinations/info/cruise-shore-excursions-terms-and-conditions"
                      >
                        Terms & Conditions
                      </a>
                    </>
                  )}

                  {/* checkbox text with links for b2c */}
                  {!isAgency && (
                    <>
                      I have read and accept the Cruise Control{" "}
                      <a
                        className="text-blue hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.tui.co.uk/destinations/info/cruise-shore-excursions-terms-and-conditions"
                      >
                        Terms & Conditions
                      </a>
                    </>
                  )}
                </Checkbox>
                {errors.importantInfo && (
                  <div className="errors">{errors.importantInfo}</div>
                )}
              </div>
            </div>
          </div>

          {errors.length > 0 && (
            <div className="container my-4 errors">
              {errors.map((error: any, index: number) => (
                <p key={index}>{error}</p>
              ))}
            </div>
          )}

          {/*cost sum and continue button*/}
          <CartSumInfo
            selectedItemsSum={getCartSumBySelectedCart()}
            onClick={processForm}
            inProgress={continueButtonDisabled}
          />

          {!!tokenError && (
            <div className="container my-8 flex">
              <div className="errors">{tokenError}</div>
            </div>
          )}
        </form>
      </div>
      <ErrorModal
        show={errorMessage !== ""}
        onClose={() => setErrorMessage("")}
        title="Error"
        message={errorMessage}
      />
    </div>
  );
};

export default PassengerDetails;
