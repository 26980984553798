import { FC, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import CartElements from "./cartElements/CartElementsExcursions/CartElements";
import { imagePath } from "../utils/imageUtils";
import SvgIcon from "../components/atoms/SvgIcon";
import CartElementsTac from "./cartElements/CartElementsTAC/CartElementsTac";
import CartElementOswTreatment from "./cartElements/CartElementsOswTreatments/CartElementOswTreatment";
import {
  setScrollToItineraryEntry,
  toggleExpand,
} from "../actions/itineraryActions";
import { formatPortName } from "../utils/cruiseUtils";
import CartElementsMusement from "modules/musement/cartElements/CartElementsMusement";
import { IPort } from "interfaces/ReduxState/IItinerary";
import { IItineraryExcursion } from "interfaces/Musement/IItineraryExcursion";
import { convertDMYtoYMD } from "utils/dateTimeUtils";
import CartElementsMxpDining from "./cartElements/mxp/CartElementsMxpDining";
import useAppSelector from "hooks/useAppSelector";

interface ExcursionParams {
  excursions: IItineraryExcursion[];
  isFirstDay: boolean;
  isLastDay: boolean;
  type: string;
  port: IPort;
  id: string;
  handlingInProgress: boolean;
}

const getExcursionsMusementLink = ({
  excursions,
  isFirstDay,
  isLastDay,
  type,
  port,
  id,
  handlingInProgress,
}: ExcursionParams) => {
  const firstOrLastDay = isFirstDay || isLastDay;

  if (firstOrLastDay || type === "Sea") {
    return null;
  }

  if (handlingInProgress) {
    console.error(
      `Excursions Coming Soon. Reason For Port: ${formatPortName(
        type,
        port
      )} action required`
    );
    return <span className="sm:mr-4">Excursions coming soon</span>;
  }

  if (!excursions || excursions?.length === 0) {
    // console.error(
    //   `Excursions Coming Soon Reason For Port: ${formatPortName(
    //     type,
    //     port
    //   )} is missing excursions. Excursions legnth: ${excursions?.length}`
    // );
    return <span className="sm:mr-4">Excursions coming soon</span>;
  }

  if (excursions?.some((excursion) => excursion.duration_range === null)) {
    const excursionsMissingDurationRange = excursions?.filter(
      (excursion) => excursion.duration_range === null
    );
    excursionsMissingDurationRange.forEach((excursion) => {
      // console.error(
      //   `Excursions Coming Soon Reason For Port: ${formatPortName(
      //     type,
      //     port
      //   )} and Excursion: ${
      //     excursion.title
      //   } is missing parameter duration_range`
      // );
    });
    return <span className="sm:mr-4">Excursions coming soon</span>;
  }

  return (
    <Link
      className="text-blue underline sm:mr-4"
      to={`/musement/excursions/${id}`}
    >
      Book excursions
      <SvgIcon name="chevron-right" className="w-4 h-4 inline" />
    </Link>
  );
};

type Props = {
  scrollTo: boolean;
  firstDay: boolean;
  lastDay: boolean;
  type: string;
  id: string;
  numberOfBookableSpaTreatments: number;
  numberOfBookableExcursions: number;
  shouldShowMusementExcursions: boolean;
  dayOfCruise: number;
  nrItems: number;
  port: IPort;
  expanded: boolean;
  isTacBookingEnabled: boolean;
  date: string;
  arrival: string;
  departure: string;
  shouldShowOSW: boolean;
  shouldShowMxpDining: boolean;
  excursions: IItineraryExcursion[];
};

const ItineraryEntry: FC<Props> = ({
  scrollTo,
  firstDay,
  lastDay,
  type,
  id,
  numberOfBookableSpaTreatments,
  numberOfBookableExcursions,
  dayOfCruise,
  nrItems,
  port,
  expanded,
  isTacBookingEnabled,
  date,
  shouldShowOSW,
  shouldShowMxpDining,
  excursions,
  shouldShowMusementExcursions,
}) => {
  const handlingInProgress = useAppSelector(
    (state) => state.cart.handlingInProgress
  );
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollTo) {
      window.scrollTo(0, scrollRef.current!.offsetTop - 100);
      setScrollToItineraryEntry(null);
    }
  }, [scrollTo]);

  const getExcursionsLink = () => {
    const firstOrLastDay = firstDay || lastDay;

    if (firstOrLastDay || type === "Sea") {
      return null;
    }

    if (numberOfBookableExcursions === 0) {
      return <span className="sm:mr-4">Excursions coming soon</span>;
    }

    return (
      <Link className="text-blue underline sm:mr-4" to={`/excursions/${id}`}>
        Book excursions
        <SvgIcon name="chevron-right" className="w-4 h-4 inline" />
      </Link>
    );
  };

  const getSpecialityDiningsLink = () => {
    return (
      !lastDay && (
        <div className="mt-2 sm:mt-0 sm:mr-4">
          <Link
            className="text-blue underline"
            to={{
              pathname: "/tac/restaurants",
              state: {
                date: date,
              },
            }}
          >
            {isTacBookingEnabled ? "Book" : "View"} Speciality Dining
            <SvgIcon name="chevron-right" className="w-4 h-4 inline" />
          </Link>
        </div>
      )
    );
  };

  const getMxpSpecialityDiningsLink = () => {
    return (
      !lastDay && (
        <div className="mt-2 sm:mt-0 sm:mr-4">
          <Link
            className="text-blue underline"
            to={{
              pathname: `/mxp/restaurants/${convertDMYtoYMD(date)}`,
            }}
          >
            Book Speciality Dining
            <SvgIcon name="chevron-right" className="w-4 h-4 inline" />
          </Link>
        </div>
      )
    );
  };

  const getSpaTreatmentsLink = () => {
    if (lastDay || numberOfBookableSpaTreatments === 0) {
      return null;
    }

    return (
      <div className="mt-2 sm:mt-0 sm:mr-4">
        <Link className="text-blue underline" to={`/spa-and-wellness/${id}`}>
          Book Spa and Salon Treatments
          <SvgIcon name="chevron-right" className="w-4 h-4 inline" />
        </Link>
      </div>
    );
  };

  return (
    <div className="my-2" ref={scrollRef}>
      <div className="flex bg-blue-lightest shadow">
        <div className="flex w-16 flex-shrink-0 bg-blue-lighter items-center justify-center">
          <div>DAY {dayOfCruise}</div>
        </div>

        <div className="p-2 flex-grow relative">
          <div className={`font-bold ${nrItems > 0 ? "pr-8 mr-3" : ""}`}>
            {formatPortName(type, port)}
          </div>
          {nrItems > 0 && (
            <div className="absolute right-0 top-0">
              <div className="mr-4 mt-4 flex items-center">
                {!expanded && (
                  <div className="hidden sm:block mr-4">
                    {nrItems} item
                    {nrItems > 1 ? "s" : ""} added
                  </div>
                )}
                <button
                  className="focus:outline-none"
                  onClick={() => toggleExpand(id)}
                >
                  <img
                    alt=""
                    className="w-8 h-8"
                    src={imagePath(expanded ? "up.svg" : "down.svg")}
                  />
                </button>
              </div>
            </div>
          )}

          <div className="mt-2 sm:flex">
            {shouldShowMusementExcursions
              ? getExcursionsMusementLink({
                  excursions,
                  isFirstDay: firstDay,
                  isLastDay: lastDay,
                  type,
                  port,
                  id,
                  handlingInProgress,
                })
              : getExcursionsLink()}

            {lastDay && (
              <div>
                Please speak to the onboard team for details of available
                excursions, restaurants and Spa services
              </div>
            )}

            {shouldShowMxpDining
              ? getMxpSpecialityDiningsLink()
              : getSpecialityDiningsLink()}

            {shouldShowOSW && getSpaTreatmentsLink()}
          </div>
        </div>
      </div>

      {nrItems > 0 && expanded && (
        <div className="bg-blue-lightest-25 shadow p-4">
          <CartElements itineraryId={id} cartType="itinerary" />
          <CartElements itineraryId={id} cartType="booking" />
          <CartElements itineraryId={id} cartType="openRefund" />
          <CartElementsMxpDining date={convertDMYtoYMD(date)} />
          <CartElementsMusement date={date} />
          <CartElementsTac cartType="itinerary" date={date} />
          <CartElementsTac cartType="booking" date={date} />
          <CartElementsTac cartType="openRefund" date={date} />
          <CartElementOswTreatment date={date} />
        </div>
      )}
    </div>
  );
};

export default ItineraryEntry;
