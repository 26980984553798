import Checkbox from "components/Checkbox";
import Price from "components/Price/Price";
import Loader from "components/atoms/Loader";
import ModalFooter from "components/modals/ModalFooter";
import useGet from "hooks/useGet";
import { FC, useEffect, useState } from "react";
import { usePostAddActivityToPlanner } from "../hooks/usePostAddActivityToPlanner";
import {
  IExcursionPassenger,
  IPassenger,
  IPassengerArray,
} from "interfaces/Common/IPassenger";
import useAppSelector from "hooks/useAppSelector";
import { IExcursionProduct } from "interfaces/Musement/IExcursionProduct";
import {
  addDuration,
  areEventsOverlapping,
  calculateYearsDifference,
  checkIfAgeInRange,
  formatDateSlashesToDashes,
} from "utils/dateTimeUtils";
import { IMusementActivitiesCartType } from "interfaces/Musement/IExcursionCartType";
import { getPassengerType } from "utils/passengerUtils/passengerUtils";

const MUSEMENT_PRODUCT_HOLDER_CODE_NORMALIZED = {
  ADULT: "ADULT",
  CHILDREN: "CHILDREN",
  CUSTOM: "CUSTOM",
  FAMILY: "FAMILY",
  GROUP: "GROUP",
  INFANT: "INFANT",
  REGULAR: "REGULAR",
  SENIOR: "SENIOR",
  STUDENT: "STUDENT",
};

const PASSENGER_TYPES = {
  CHILD: "Child",
  ADULT: "Adult",
  BABY: "Baby",
};

const DISABLED_REASONS_MESSAGES = {
  ALREADY_PREBOOKED: "You have already selected a tour for this port",
  ALREADY_BOOKED: "You have already booked a tour for this port",
  TOO_YOUNG_TO_JOIN_TRIP: "This guest is too young to join this trip",
  CHILD_CAN_NOT_GO_ALONE_ON_TRIP:
    "A child must always be accompanied by an adult",
  ADULT_MUST_ACCOMPANY_CHILD_INFANT: "Adults must accompany Children/Infant",
};

const SUCCESS_MESSAGE = "Added to Planner Successfully";
const ERROR_MESSAGE =
  "We’re sorry, but your booking can’t be processed at the moment – please try again later.";

const findExcursionByPassengerId = (
  collection: Array<IMusementActivitiesCartType[]>,
  passengerId: string
) => {
  const foundExcursions: IMusementActivitiesCartType[] = [];

  collection.forEach((element: IMusementActivitiesCartType[]) => {
    element.forEach((excursion: IMusementActivitiesCartType) => {
      excursion?.persons.forEach((person: IExcursionPassenger) => {
        if (person.passengerId === passengerId) {
          foundExcursions.push(excursion);
        }
      });
    });
  });

  return foundExcursions;
};

const checkIfPassengerActivitiesOverlapping = (
  excrusionsList: IMusementActivitiesCartType[],
  startTime: string,
  activityDuration: string
) => {
  let isOverlapping = false;

  excrusionsList.find((excursion: IMusementActivitiesCartType) => {
    const endTime = addDuration(startTime, activityDuration);
    const excursionEndTime = addDuration(
      excursion.product.scheduledTime,
      excursion.durationRange
    );

    isOverlapping = areEventsOverlapping(
      { startTime: startTime, endTime: endTime },
      {
        startTime: excursion.product.scheduledTime,
        endTime: excursionEndTime,
      }
    );

    return isOverlapping;
  });

  return isOverlapping;
};

type MusementAddToPlannerFormType = {
  itineraryId: string;
  adultPrice: number | null;
  childPrice: number | null;
  childAgeRange: string;
  babyAgeRange: string;
  itineraryDate: string;
  products: IExcursionProduct[];
  onCancel: () => void;
  onSuccess: ({ img, message }: { img: string; message: string }) => void;
  onError: ({ img, message }: { img: string; message: string }) => void;
  startTime: string;
  activityDuration: string;
};

const MusementAddToPlannerForm: FC<MusementAddToPlannerFormType> = ({
  adultPrice,
  childPrice,
  childAgeRange,
  babyAgeRange,
  itineraryId,
  itineraryDate,
  products,
  onCancel,
  onSuccess,
  onError,
  startTime,
  activityDuration,
}) => {
  const { passengers }: IPassengerArray = useGet("precruise/status", []);
  const [internalPassengers, setInternalPassengers] = useState<IPassenger[]>(
    []
  );

  const bookedMusementExcursions = useAppSelector(
    (state) => state.bookings.musement?.[itineraryDate]
  );

  const cartMusementExcursions = useAppSelector(
    (state) => state.cart.musement?.[itineraryDate]
  );

  const { postAddActivityToPlanner, error, loading, data } =
    usePostAddActivityToPlanner();
  const [selectedinternalPassengers, setSelectedinternalPassengers] = useState<
    string[]
  >([]);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (passengers) {
      const updatedPassengers = passengers.map((passenger) => {
        return {
          ...passenger,
          type: getPassengerType(
            passenger?.dob,
            itineraryDate,
            childAgeRange,
            babyAgeRange
          ),
        };
      });
      setInternalPassengers(updatedPassengers);
    }
  }, [itineraryDate, passengers, childAgeRange, babyAgeRange]);

  let disabledReason = "";

  const filterSelectedAdultinternalPassengersFullInformation = (
    updatedSelectedinternalPassengers: string[]
  ) => {
    return internalPassengers?.filter(
      (passenger: IPassenger) =>
        passenger?.type === "Adult" &&
        updatedSelectedinternalPassengers?.includes(passenger?.passengerId)
    );
  };

  const getTotalPrice = () => {
    return internalPassengers.reduce((total: number, passenger: IPassenger) => {
      if (selectedinternalPassengers.includes(passenger.passengerId)) {
        if (passenger.type === PASSENGER_TYPES.ADULT && adultPrice) {
          return total + adultPrice;
        } else if (passenger.type === PASSENGER_TYPES.CHILD && childPrice) {
          return total + childPrice;
        }
      }

      return total;
    }, 0);
  };

  const addToCart = async () => {
    // Get products from MM api
    const adultProduct = products?.find(
      (product) =>
        product?.holder_code_normalized ===
        MUSEMENT_PRODUCT_HOLDER_CODE_NORMALIZED.ADULT
    );

    const childProduct = products?.find(
      (product) =>
        product?.holder_code_normalized ===
        MUSEMENT_PRODUCT_HOLDER_CODE_NORMALIZED.CHILDREN
    );

    const infantProduct = products?.find(
      (product) =>
        product?.holder_code_normalized ===
        MUSEMENT_PRODUCT_HOLDER_CODE_NORMALIZED.INFANT
    );

    // Get internalPassengers full information from TUI BE (prebook)
    const selectedAdultinternalPassengersFullInformation =
      filterSelectedAdultinternalPassengersFullInformation(
        selectedinternalPassengers
      );

    const selectedChildinternalPassengersFullInformation =
      internalPassengers?.filter(
        (passenger: IPassenger) =>
          passenger?.type === PASSENGER_TYPES.CHILD &&
          selectedinternalPassengers?.includes(passenger?.passengerId)
      );

    const selectedInfantinternalPassengersFullInformation =
      internalPassengers?.filter(
        (passenger: IPassenger) =>
          passenger?.type === PASSENGER_TYPES.BABY &&
          selectedinternalPassengers?.includes(passenger?.passengerId)
      );

    const productsToBeAdded = [];

    // Add Adults
    if (selectedAdultinternalPassengersFullInformation?.length > 0) {
      productsToBeAdded.push({
        product_identifier: adultProduct?.product_id,
        type: adultProduct?.type,
        quantity: selectedAdultinternalPassengersFullInformation?.length,
        itineraryId,
        persons: selectedAdultinternalPassengersFullInformation,
        itineraryDate,
      });
    }

    // Add Children
    if (selectedChildinternalPassengersFullInformation?.length > 0) {
      productsToBeAdded.push({
        product_identifier: childProduct?.product_id,
        type: childProduct?.type,
        quantity: selectedChildinternalPassengersFullInformation?.length,
        itineraryId,
        persons: selectedChildinternalPassengersFullInformation,
        itineraryDate,
      });
    }

    // Add Infants (Babies)
    if (selectedInfantinternalPassengersFullInformation?.length > 0) {
      productsToBeAdded.push({
        product_identifier: infantProduct?.product_id,
        type: infantProduct?.type,
        quantity: selectedInfantinternalPassengersFullInformation?.length,
        itineraryId,
        persons: selectedInfantinternalPassengersFullInformation,
        itineraryDate,
      });
    }

    //@ts-ignore
    await postAddActivityToPlanner({ productsToBeAdded });
  };

  const togglePassenger = (passengerId: string) => {
    const updatedSelectedinternalPassengers =
      selectedinternalPassengers.includes(passengerId)
        ? selectedinternalPassengers.filter(
            (selectedId) => selectedId !== passengerId
          )
        : [...selectedinternalPassengers, passengerId];
    const updatedSelectedAdultinternalPassengers =
      filterSelectedAdultinternalPassengersFullInformation(
        updatedSelectedinternalPassengers
      );
    const updatedErrorMessage =
      updatedSelectedAdultinternalPassengers?.length > 0 ||
      updatedSelectedinternalPassengers?.length === 0
        ? ""
        : DISABLED_REASONS_MESSAGES.ADULT_MUST_ACCOMPANY_CHILD_INFANT;

    setSelectedinternalPassengers(updatedSelectedinternalPassengers);
    setErrorMessage(updatedErrorMessage);
  };

  const checkIfPassengerIsBooked = (
    collection: Array<IMusementActivitiesCartType[]>,
    passengerId: string
  ) => {
    if (!collection) {
      return false;
    }

    const excursionsInCart = findExcursionByPassengerId(
      collection,
      passengerId
    );

    if (excursionsInCart.length > 0) {
      const isActivityOverlapping = checkIfPassengerActivitiesOverlapping(
        excursionsInCart,
        startTime,
        activityDuration
      );

      return isActivityOverlapping;
    }

    return false;
  };

  const shouldDisablePassengerCheckbox = (passengerId: string) => {
    const passengerIsBooked = checkIfPassengerIsBooked(
      bookedMusementExcursions,
      passengerId
    );

    if (passengerIsBooked) {
      disabledReason = DISABLED_REASONS_MESSAGES.ALREADY_BOOKED;
      return true;
    }

    const passengerIsPreBooked = checkIfPassengerIsBooked(
      cartMusementExcursions,
      passengerId
    );

    if (passengerIsPreBooked) {
      disabledReason = DISABLED_REASONS_MESSAGES.ALREADY_PREBOOKED;
      return true;
    }

    const childPassenger = internalPassengers?.find(
      (passenger) =>
        passenger?.passengerId === passengerId && passenger.type === "Child"
    );

    const babyPassenger = internalPassengers?.find(
      (passenger) =>
        passenger?.passengerId === passengerId && passenger.type === "Baby"
    );

    if (childPassenger || babyPassenger) {
      const selectedInternalAdultPassengers = internalPassengers?.filter(
        (passenger: IPassenger) => {
          const passengerIsAlreadySelected = selectedinternalPassengers.find(
            (passengerId: string) => passengerId === passenger.passengerId
          );
          const passengerIsAdult = passenger?.type === "Adult";
          return passengerIsAlreadySelected && passengerIsAdult;
        }
      );

      if (selectedInternalAdultPassengers.length === 0) {
        disabledReason =
          DISABLED_REASONS_MESSAGES.CHILD_CAN_NOT_GO_ALONE_ON_TRIP;
        return true;
      } else {
        if (childPassenger) {
          const isChildOutAgeRangeLimits = !checkIfAgeInRange(
            calculateYearsDifference(
              childPassenger.dob,
              formatDateSlashesToDashes(itineraryDate)
            ),
            childAgeRange
          );

          disabledReason = isChildOutAgeRangeLimits
            ? DISABLED_REASONS_MESSAGES.TOO_YOUNG_TO_JOIN_TRIP
            : "";
          return isChildOutAgeRangeLimits;
        }

        if (babyPassenger) {
          const isBabyOutAgeRangeLimits = !checkIfAgeInRange(
            calculateYearsDifference(
              babyPassenger.dob,
              formatDateSlashesToDashes(itineraryDate)
            ),
            babyAgeRange
          );

          disabledReason = isBabyOutAgeRangeLimits
            ? DISABLED_REASONS_MESSAGES.TOO_YOUNG_TO_JOIN_TRIP
            : "";
          return isBabyOutAgeRangeLimits;
        }

        disabledReason = DISABLED_REASONS_MESSAGES.TOO_YOUNG_TO_JOIN_TRIP;
        return true;
      }
    }

    disabledReason = "";
    return false;
  };

  if (!internalPassengers) {
    return <Loader />;
  }

  if (loading) {
    return <Loader text="Adding to planner..." />;
  }

  if (data) {
    onSuccess({
      img: "success-tick-green.svg",
      message: SUCCESS_MESSAGE,
    });
  }

  if (error) {
    onError({
      img: "close.png",
      message: ERROR_MESSAGE,
    });
  }

  return (
    <>
      <div className="flex flex-col flex-grow">
        <div className="flex-grow bg-white py-4 px-2 sm:px-4">
          <div className="font-bold">Who's travelling on the excursion?</div>
          <div>
            £{adultPrice} per adult, £{childPrice || "/"} per child{" "}
            {childAgeRange}
          </div>
          <div className="bg-blue-lightest px-4 py-2 mt-4">
            {internalPassengers.map((passenger: IPassenger) => (
              <div className="my-2" key={passenger.passengerId}>
                <Checkbox
                  label={`${passenger.firstName} ${passenger.lastName}`}
                  checked={selectedinternalPassengers?.includes(
                    passenger.passengerId
                  )}
                  disabled={shouldDisablePassengerCheckbox(
                    passenger.passengerId
                  )}
                  disabledReason={disabledReason}
                  handleCheckboxChange={() =>
                    togglePassenger(passenger.passengerId)
                  }
                />
              </div>
            ))}
          </div>

          <Price preText="Total price" price={getTotalPrice().toString()} />
          {errorMessage && <div className="errors mt-2">{errorMessage}</div>}
        </div>

        <ModalFooter
          onCancel={onCancel}
          actionDisabled={
            errorMessage?.length > 0 || selectedinternalPassengers?.length === 0
          }
          actionButtonText="Add to planner"
          onActionClick={addToCart}
        />
      </div>
    </>
  );
};

export default MusementAddToPlannerForm;
