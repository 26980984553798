import axios, { Method } from "axios/index";
import browserHistory from "../utils/history";
import { getHubBaseUrl, getHubUrl } from "../config/configUtils";
import { logoutAgency } from "./authenticationActions";
import { store } from "WithProviders";

export const getResourceImageUrl = (
  resourceId?: string | null,
  { width = 0, category = "" } = {}
) => {
  // check if "resourseId" is a string and a "base64" png image
  if (
    typeof resourceId === "string" &&
    resourceId.includes("data:image/png;base64")
  ) {
    return resourceId;
  }

  // check if img url is from new cloud hub and return the url directly
  if (
    typeof resourceId === "string" &&
    resourceId.includes("/api/spa-service/")
  ) {
    return resourceId;
  }

  // check if img url is from new cloud hub and return the url directly
  if (
    typeof resourceId === "string" &&
    resourceId?.toLowerCase()?.includes("mxp")
  ) {
    return resourceId;
  }

  // otherwise - build "blob" image
  const IMAGES_URL = getHubBaseUrl();
  let imageUrl = `${IMAGES_URL}blobs/image?reference=${resourceId}`;

  if (width) {
    imageUrl += `&width=${width}`;
  }

  if (category) {
    imageUrl += `&category=${category}`;
  }

  return imageUrl;
};

export const getFromHub = (
  options: any,
  successCallback?: (data: any) => void,
  errorCallback?: (e: any) => void
) => {
  return hubNetworkRequest(
    "GET",
    options,
    null,
    successCallback,
    errorCallback
  );
};

export const postToHub = (
  options: any,
  payload: any,
  successCallback?: (data: any) => void,
  errorCallback?: (e: any) => void
) => {
  return hubNetworkRequest(
    "POST",
    options,
    payload,
    successCallback,
    errorCallback
  );
};

export const putToHubNew = (
  options: any,
  payload: any,
  successCallback: () => void,
  errorCallback: () => void
) => {
  return hubNetworkRequest(
    "PUT",
    options,
    payload,
    successCallback,
    errorCallback
  );
};

export const deleteFromHubNew = (
  options: any,
  successCallback: () => void,
  errorCallback: () => void
) => {
  return hubNetworkRequest(
    "DELETE",
    options,
    null,
    successCallback,
    errorCallback
  );
};

type SuccessCallback = (data: any) => void;
type ErrorCallback = (e: any) => void;

const hubNetworkRequest = async (
  method: Method,
  options: any,
  payload: any,
  successCallback?: SuccessCallback,
  errorCallback?: ErrorCallback
) => {
  let path = "";
  let headers = {};
  let logoutOn403 = true;

  if (typeof options === "object") {
    ({ path, headers = {}, logoutOn403 = true } = options);
  }

  if (typeof options === "string") {
    path = options;
  }

  try {
    const HUB_URL = getHubUrl();
    const response = await axios({
      method,
      url: HUB_URL + path,

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        SessionId: store.getState().authentication.sessionId,
        ...headers,
      },
      data: payload,
    });
    successCallback && successCallback(response.data);
    return response.data;
  } catch (e: any) {
    errorCallback &&
      errorCallback({
        response: e.response,
        message: e.message,
      });

    if (e.response) {
      if (
        e.response.status === 409 &&
        e.response.data === "Itinerary closed for ancillary booking"
      ) {
        browserHistory.push("/");
      }

      if (e.response.status === 403 && logoutOn403) {
        logoutAgency(); //this will log out agency as well as passenger
        browserHistory.push("/login");
      }
    }

    return { error: e };
  }
};

export const postApiCall = async ({
  url,
  payload,
}: {
  url: string;
  payload: any;
}) => {
  try {
    const response = await axios.post(url, payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Content-Security-Policy": "connect-src 'self",

        // SessionId: store.getState().authentication.sessionId,
      },
      data: payload,
    });
    return response.data;
  } catch (e: any) {
    if (e.response) {
      if (
        e.response.status === 409 &&
        e.response.data === "Itinerary closed for ancillary booking"
      ) {
        browserHistory.push("/");
      }

      if (e.response.status === 403) {
        logoutAgency(); //this will log out agency as well as passenger
        browserHistory.push("/login");
      }
    }

    return { error: e };
  }
};
