import { mergeAndSortEntries } from "utils/cartUtils";
import useAppSelector from "hooks/useAppSelector";
import CartElementOswCheckout from "components/cartElements/CartElementsOswTreatments/CartElementOswCheckout";

const CartElementsCheckoutCombinedOSW = () => {
  const oswEntries = useAppSelector((state) => state.cart.osw);
  const cruiseStartDate = useAppSelector(
    (state) => state.cruises.cruiseStartDate
  );

  const combinedSortedEntries = mergeAndSortEntries(
    undefined,
    undefined,
    oswEntries,
    undefined,
    undefined,
    cruiseStartDate
  );

  return (
    <div className="bg-blue-lightest-25 px-4 py-1">
      {combinedSortedEntries.map((entry) => {
        if (entry.treatmentId) {
          return (
            <CartElementOswCheckout
              key={`${entry.treatmentId}-${entry.scheduledDay}-${entry.scheduledTime}`}
              title={entry.title}
              image={entry.image}
              itineraryId={entry.itineraryId}
              scheduledDay={entry.scheduledDay}
              scheduledTime={entry.scheduledTime}
              duration={entry.duration}
              fullName={entry.fullName}
              price={entry.price}
              dayNr={entry.dayNr}
              date={entry.date}
            />
          );
        }

        return null;
      })}
    </div>
  );
};

export default CartElementsCheckoutCombinedOSW;
