import { FC } from "react";
import {
  formatItineraryDateToOneSpaWorldDate,
  formatPortDate,
  formatTimeHM,
} from "../../../utils/dateTimeUtils";
import "moment/locale/en-gb";
import PlannerContainer from "../../../components/PlannerContainer";
import BackLink from "../../../components/molecules/BackLink";

import useAppSelector from "../../../hooks/useAppSelector";
import OneSpaWorldItem from "../OneSpaWorldItem";
import { formatPortName } from "utils/cruiseUtils";
import Loader from "components/atoms/Loader";
import { useGetSpaTreatmentsList } from "../hooks/useGetSpaTreatmentsList";
import { buildTestId } from "utils/buildTestId";
import { RouteComponentProps } from "react-router-dom";

export interface Props
  extends RouteComponentProps<{
    itineraryId: string;
  }> {}

const OneSpaWorldnessList: FC<Props> = ({ match }) => {
  const itinerary = useAppSelector((state) => {
    return state.itinerary.find(
      (itinerary) => itinerary.id === match.params.itineraryId
    );
  });

  const { data, loading } = useGetSpaTreatmentsList({
    // @ts-ignore TS FIX ME
    date: formatItineraryDateToOneSpaWorldDate(itinerary.date),
  });

  // @ts-ignore TS FIX ME
  const portName = formatPortName(itinerary.type, itinerary.port);

  if (!itinerary) {
    return null;
  }

  return (
    <div>
      <PlannerContainer />
      <div
        data-testid={buildTestId(
          "container",
          "spa treatments itinerary details"
        )}
        className="container mx-auto my-4 text-blue-dark"
      >
        <BackLink to="/itinerary" text="Cruise Planner" />
        <h1>Spa & Wellness Treatments</h1>
        <div className="text-2xl">
          DAY {itinerary.dayOfCruise} ({formatPortDate(itinerary.date)})
        </div>
        <div className="text-3xl mt-1">{portName}</div>
        {itinerary.type === "Port" && (
          <div className="text-3xl mt-1">
            Time in port: {formatTimeHM(itinerary.arrival, "Previous day")} -{" "}
            {formatTimeHM(itinerary.departure, "Next day")}
          </div>
        )}
      </div>
      <div
        data-testid={buildTestId("container", "spa treatments list")}
        className="container mx-auto px-2 sm:px-4 py-8 flex flex-col gap-y-8"
      >
        {!loading ? (
          data?.oswTreatments?.map((spaTreatment) => (
            <OneSpaWorldItem
              key={spaTreatment.serviceId}
              id={spaTreatment.serviceId}
              itineraryId={itinerary.id}
              title={spaTreatment.title}
              shortDescription={spaTreatment.shortDescription}
              image={spaTreatment.image}
              duration={spaTreatment.duration}
              standardPrice={spaTreatment.standardPrice}
              isBookable={data.isBookable}
              date={formatItineraryDateToOneSpaWorldDate(itinerary.date)}
            />
          ))
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default OneSpaWorldnessList;
