import { useEffect, useState } from "react";
import { imagePath } from "../utils/imageUtils";
import Price from "./Price/Price";
import ContentHeaderImage from "./ContentHeaderImage";
import GalleryToggleButton from "./GalleryToggleButton";
import RestaurantAvailabilityModal from "./modals/RestaurantAvailabilityModal";
import SuccessModal from "./modals/SuccessModal";
import { setScrollToItineraryEntry } from "../actions/itineraryActions";
import { getHubBaseUrl } from "../config/configUtils";
import ErrorModal from "./modals/ErrorModal";
import { FC } from "react";

type Props = {
  preselectedDetails: any;
  restaurant: any;
  date: any;
  toggleGallery: (value: any) => void;
  toggleExp360Gallery: () => void;
  isBookingEnabled: any;
  descriptions: any;
};

const RestaurantEntry: FC<Props> = ({
  preselectedDetails,
  restaurant,
  date,
  toggleGallery,
  toggleExp360Gallery,
  isBookingEnabled,
  descriptions,
}) => {
  const isRestaurantPreselected =
    preselectedDetails && preselectedDetails.serviceProfileId === restaurant.id;

  const [modalType, setModalType] = useState(
    isRestaurantPreselected ? "availability" : ""
  );
  const [errorMessage, setErrorMessage] = useState("");

  const showModal = (modalName: string) => {
    document.body.style.overflow = "hidden";

    if (modalName === "success") {
      setScrollToItineraryEntry(date);
    }

    setModalType(modalName);
  };

  const hideModal = (modalName: string) => {
    document.body.style.overflow = "unset";

    if (modalName === "success") {
      setScrollToItineraryEntry(null);
    }

    setModalType("");
  };

  const showError = (errorMessage: string) => {
    setErrorMessage(errorMessage);
    showModal("error");
  };

  useEffect(() => {
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <div className="bg-white border border-blue-light my-4">
      <div className="md:flex md:flex-wrap xl:flex-nowrap">
        {/*image*/}
        <div className="w-full sm:p-4 md:w-1/2 xl:w-1/3">
          <ContentHeaderImage
            altText={restaurant.name.defaultValue + " - header"}
            resourceId={restaurant.id}
            width={400}
          >
            <GalleryToggleButton
              toggleGallery={() => toggleGallery(restaurant.id)}
              toggleExp360Gallery={toggleExp360Gallery}
            />
          </ContentHeaderImage>
        </div>

        <div className="p-4 md:w-1/2 md:pl-0 xl:w-1/2 xl:flex-grow sm:pt-0 md:pt-4">
          {!isBookingEnabled && (
            <div className="text-3xl text-blue uppercase">
              {restaurant.name.defaultValue}
            </div>
          )}

          {isBookingEnabled && (
            <button
              className="text-3xl text-blue underline uppercase"
              onClick={() => showModal("availability")}
            >
              {restaurant.name.defaultValue}
            </button>
          )}

          <ul className="list-square list-inside my-2">
            <li>
              Open {restaurant.fromTime} - {restaurant.toTime},{" "}
              {restaurant.location}
            </li>
          </ul>

          <div>{descriptions && descriptions.description}</div>

          {descriptions && descriptions.notes && (
            <div className="mt-4 flex">
              <div className="flex-shrink-0 mr-2">
                <img
                  alt=""
                  className="w-4 h-4"
                  src={imagePath("infoicon_inverted.svg")}
                />
              </div>
              <div className="flex-1">{descriptions.notes}</div>
            </div>
          )}
        </div>

        <div className="w-full xl:w-1/5 px-4 xl:p-4">
          <div className="bg-blue-lightest-25 p-4 w-full leading-normal mb-4 sm:flex xl:flex-col xl:mb-0">
            <div className="sm:flex-grow">
              <div>{descriptions && descriptions.included}</div>
              {restaurant.pdfDownloadAllowed && (
                <div>
                  <a
                    href={`${getHubBaseUrl()}blobs/pdf?reference=${
                      restaurant.id
                    }`}
                    className="underline text-blue"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    See example menu
                  </a>
                </div>
              )}
            </div>
            <div className="text-right leading-none xl:mt-3">
              <Price
                preText="from"
                price={restaurant.fromAdultPrice}
                postText=" pp"
              />
              {!isBookingEnabled && (
                <div className="mt-2 text-sm text-left">
                  We’ll contact you when this is available to book
                </div>
              )}

              {isBookingEnabled && (
                <button
                  className="action-button action-button-enabled w-full mt-3"
                  onClick={() => showModal("availability")}
                >
                  BOOK NOW
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {modalType === "availability" && (
        <RestaurantAvailabilityModal
          restaurant={restaurant}
          date={date}
          closeModal={() => hideModal("availability")}
          onSuccess={() => showModal("success")}
          onError={(errorMessage: any) => showError(errorMessage)}
          preselectedDetails={
            isRestaurantPreselected ? preselectedDetails : false
          }
        />
      )}

      <SuccessModal
        show={modalType === "success"}
        onClose={() => hideModal("success")}
        title="Restaurant reservation"
        message="Restaurant reservation added to your planner"
      />

      <ErrorModal
        show={modalType === "error"}
        onClose={() => hideModal("error")}
        title="Error"
        message={errorMessage}
      />
    </div>
  );
};

export default RestaurantEntry;
