import { FC } from "react";
import { getCruisesInfo } from "../utils/cruiseUtils";
import {
  formatItineraryDepartDate,
  subtractDaysFromDate,
} from "../utils/dateTimeUtils";
import { imagePath } from "../utils/imageUtils";
import useAppSelector from "../hooks/useAppSelector";

type Props = {
  nrRestaurants: number;
  nrExcursions: number;
};

const CruisesItineraryInfo: FC<Props> = ({ nrRestaurants, nrExcursions }) => {
  const cruises = useAppSelector((state) => state.cruises.cruiseDetails);
  const cruisesInfo = getCruisesInfo(cruises);

  return (
    <div className="bg-blue-lightest-25 shadow p-4 xl:flex-grow">
      {/* cruise name */}
      <div className="text-3xl text-blue-dark font-bold mb-4">
        {cruisesInfo.cruiseName}
      </div>

      <div className="sm:flex md:block">
        {/* cruise info */}
        <div className="border-b border-blue-dark pb-4 sm:border-b-0 sm:border-r sm:w-1/2 sm:pr-4 md:border-b md:border-r-0 md:w-full md:pr-0">
          <div className="flex items-center mb-2">
            <img
              className="w-4 h-4 mr-2 inline flex-shrink-0"
              alt="calendar icon"
              src={imagePath("ship.svg")}
            />
            <span>Sailing on {cruisesInfo.shipName}</span>
          </div>

          <div className="flex items-center mb-2">
            <img
              className="w-4 h-4 mr-2 inline flex-shrink-0"
              alt="calendar icon"
              src={imagePath("calendar.svg")}
            />
            <span>
              Departs {formatItineraryDepartDate(cruisesInfo.cruiseStart)}
            </span>
          </div>
          <div className="flex items-center mb-2">
            <img
              className="w-4 h-4 mr-2 inline flex-shrink-0"
              alt="calendar icon"
              src={imagePath("moon.svg")}
            />
            <span>{cruisesInfo.cruiseDuration} nights cruise</span>
          </div>
        </div>

        {/* nrExcursions and nrRestaurants */}
        <div className="mt-4 sm:mt-0 sm:w-1/2 sm:pl-4 md:mt-4 md:w-full md:pl-0">
          <div className="mb-2">
            Get ahead and plan your perfect cruise experience by choosing from:
          </div>
          <ul className="list-square list-inside text-blue-dark">
            {/* Total number of excursions available not ready on BE side */}
            {/* <li className="mt-2">
              {nrExcursions} excursions, available to pre-book before{" "}
              {subtractDaysFromDate(cruisesInfo.cruiseStart, 2)}
            </li> */}
            {nrRestaurants > 0 && (
              <li className="mt-2">
                {nrRestaurants} speciality restaurants, available to pre-book
                before {subtractDaysFromDate(cruisesInfo.cruiseStart, 6)}
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CruisesItineraryInfo;
