import ImageGallery from "react-image-gallery";
import {
  convertDMYtoYMD,
  convertYMDtoDMY,
  formatPortDate,
  formatTimeHM,
} from "../../../utils/dateTimeUtils";
import ModalGallery from "../../../components/modals/ModalGallery";
import { formatPortName } from "../../../utils/cruiseUtils";
import BackLink from "../../../components/molecules/BackLink";
import { useGetRestaurants } from "./hooks/useGetRestaurants";
import Loader from "components/atoms/Loader";
import useAppSelector from "hooks/useAppSelector";
import MxpRestaurantEntry from "./MxpRestaurantEntry";

const isBookingEnabled = true;
const showGallery = false;

const MxpRestaurantsList = (props: any) => {
  const dateYMD = props.match.params.itineraryDate;

  const itineraryByDate = useAppSelector((state) =>
    state.itinerary.find(({ date }) => date === convertYMDtoDMY(dateYMD))
  );

  const cruiseDetails = useAppSelector((state) => state.cruises);

  const { data: restaurants, isFetching } = useGetRestaurants({
    fromDate: convertDMYtoYMD(cruiseDetails?.cruiseStartDate ?? ""),
    toDate: convertDMYtoYMD(cruiseDetails?.cruiseEndDate ?? ""),
  });

  const getDataForDate = (array: any, date: any) => {
    for (const item of array) {
      if (item[date]) {
        return item[date];
      }
    }
    return [];
  };

  if (!itineraryByDate) {
    return <h3>Couldn't find itinerary for specified date.</h3>;
  }

  const specificDateData = getDataForDate(
    restaurants,
    convertDMYtoYMD(itineraryByDate.date)
  );

  if (isFetching) {
    return <Loader />;
  }

  if (specificDateData?.length === 0) {
    return <h4>We are sorry, there are no restaurants available</h4>;
  }

  if (!isFetching && (restaurants === undefined || restaurants?.length === 0)) {
    return (
      <div className="container mx-auto my-4">
        <BackLink to="/itinerary" text="Cruise Planner" />
        <div className="text-xl mt-4 mb-6">
          Unfortunately, Speciality Dining is not yet available to book on your
          chosen date, please check back again later.
        </div>
      </div>
    );
  }

  const date = itineraryByDate?.date;
  const day = itineraryByDate?.dayOfCruise;

  const port = formatPortName(itineraryByDate.type, itineraryByDate.port);
  const toggleGallery = () => {};

  return (
    <div>
      <div className="container mx-auto my-4 text-blue-dark">
        <BackLink to="/itinerary" text="Cruise Planner" />
        <h1>Restaurant options</h1>
        <div className="text-2xl">
          DAY {day} ({formatPortDate(date)})
        </div>
        <div className="text-3xl mt-1">{port}</div>
        {itineraryByDate.type === "Port" && (
          <div className="text-3xl mt-1">
            Time in port:{" "}
            {formatTimeHM(itineraryByDate.arrival, "Previous day")} -{" "}
            {formatTimeHM(itineraryByDate.departure, "Next day")}
          </div>
        )}

        <div className="print-hidden">
          <ModalGallery show={showGallery} onClose={() => toggleGallery()}>
            <ImageGallery
              items={[]}
              lazyLoad={true}
              showIndex={true}
              showPlayButton={false}
            />
          </ModalGallery>
        </div>
      </div>

      <div className="bg-blue-lightest-25 mt-8">
        <div className="container mx-auto px-2 sm:px-4 py-8">
          {specificDateData.map((restaurant: any) => {
            return (
              <MxpRestaurantEntry
                key={restaurant.venueId}
                restaurant={restaurant}
                descriptions={"Test"}
                toggleGallery={() => toggleGallery()}
                date={date}
                preselectedDetails={false}
                isBookingEnabled={isBookingEnabled}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MxpRestaurantsList;
