import { mergeAndSortEntries } from "utils/cartUtils";
import useAppSelector from "hooks/useAppSelector";
import CartElementMxpCheckout from "../CartElementMxpCheckout/CartElementMxpCheckout";
import { IMxpAvailabilitySlot } from "interfaces/MXP/IMxpAvailabilitySlot";

interface IMxpAvailabilitySlotCheckout extends IMxpAvailabilitySlot {
  dayNr: number;
}

const CartElementsCheckoutCombinedMXP = () => {
  const mxpEntries = useAppSelector((state) => state.cart.mxp);
  const cruiseStartDate = useAppSelector(
    (state) => state.cruises.cruiseStartDate
  );

  const combinedSortedEntries = mergeAndSortEntries(
    undefined,
    undefined,
    undefined,
    undefined,
    mxpEntries,
    cruiseStartDate
  );

  return (
    <div className="bg-blue-lightest-25 px-4 py-1">
      {combinedSortedEntries.map((entry: IMxpAvailabilitySlotCheckout) => {
        if (entry.VenueId) {
          return (
            <CartElementMxpCheckout
              key={`${entry.VenueId}`}
              venueId={entry.VenueId}
              title={entry.VenueName}
              scheduledDay={entry.Date}
              scheduledTime={entry.AvailableTime}
              price={entry?.totalAmount}
              dayNr={entry.dayNr}
              nrGuests={entry.numberOfPersons}
            />
          );
        }

        return null;
      })}
    </div>
  );
};

export default CartElementsCheckoutCombinedMXP;
