import { IExcursionProduct } from "interfaces/Musement/IExcursionProduct";
import { getAgeRangeFromMusementProductName } from "utils/getAgeRangeFromMusementProductName";

const TYPE_CHILDREN = "CHILDREN";

const TYPE_BABY = "INFANT";

type Props = {
  products?: IExcursionProduct[];
};

export const useGetExcursionProductsAgeRanges = ({ products }: Props) => {
  const childProduct = products?.find(
    (product: IExcursionProduct) =>
      product.holder_code_normalized === TYPE_CHILDREN
  );

  const babyProduct = products?.find(
    (product: IExcursionProduct) => product.holder_code_normalized === TYPE_BABY
  );

  const childAgeRange = getAgeRangeFromMusementProductName(childProduct?.name);
  const babyAgeRange = getAgeRangeFromMusementProductName(babyProduct?.name);

  return { childAgeRange, babyAgeRange };
};
